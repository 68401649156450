import React, { Fragment, useCallback, useEffect, useState } from "react";
import FormCommon from "../common/FormCommon";
import { get, post } from "../config";
import { API_URL } from "../config/api_urls";
import DataTable from "react-data-table-component";
import { notificationtableColumn } from "../common/mockData";
import "./Notification.css";
import { Btn, H4 } from "../../../../AbstractElements";
import { Box, Button, Modal, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { toast } from "react-toastify";
import SavedMessages from "./SavedMessages";
import FilterForm from "../users/FilterForm";
import EditCommonModal from "../common/EditCommonModal";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Notification = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editorContent, setEditorContent] = React.useState('');
  const quillRef = React.useRef(null);
	const [userRole, setUserRole] = useState();
	const [gender, setGender] = useState('');
	const [csvData, setCsvData] = useState(data);
	const [editUsersList, setEditUsersList] = useState();
	const [userCategory, setUserCategory] = useState([]);
	const [countries,setCountries] = useState();
	const [states,setStates] = useState([]);
	// const [cities,setCities] = useState()
  const [city,setcity] = useState([]);
	const [hearAboutus,setHearAboutus] = useState();
  const [modalShow, setModalShow] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [userGalleryImages, setUserGalleryImages] = useState([])
  const [uuid,setUuid] = useState('')
  const [galleryImage,setGalleryImage] = useState([])
  const [categoryModal, setCategoryModal] = useState(false);

  const [userPreferences, setUserPreferences] = useState([]); 
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);

  //For Filter
  const [userRegional, setUserRegional] = useState([]);
 const [username, setUsername] = useState(''); 
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [cities, setcities] = useState('');
  const [selectedRegionalPartner, setSelectedRegionalPartner] = useState('');
  const [userCategories, setUserCategories] = useState();


  const fetchUsersList = useCallback(() => {
    get(API_URL.USERS.GET_USERS)
      .then((response) => {
        if (response?.data.length >= 0) {
          setData(response.data);
          setOriginalData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchUserRoles = useCallback(() => {
    get(API_URL.USER_ROLE.USER_ROLE_GET)
      .then((response) => {
        console.log(response);

        if (response?.data.length > 0) setUserRoles(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(userRoles);

  console.log(selectedRows);

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorContent(content); // Get plain text
  };
  console.log(editorContent);

  //whatsapp message
  const sendMessage = (row, message) => {
    console.log(row);
    message = "jhbdhjabds,am";
    const url = `https://wa.me/${row.whatsapp_number}?text=${encodeURIComponent(
      message
    )}`;

    window.open(url, "_blank");
  };

  const bulkMessage = () => {
      selectedRows.forEach((row) => {
        const phoneNumber = row.whatsapp_number;
        
        // const message = encodeURIComponent(editorContent);
        // console.log(message);
        
        // const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${editorContent}`;
        // setEditorContent('')
        // window.open(url, "_blank");

        const message = quillRef.current?.getEditor().getText();

        console.log(message);
        console.log(phoneNumber);
        
        

        const formDataa = new FormData();
          formDataa.append('recipient_number',phoneNumber);
          formDataa.append('message',message)
  
  
          // console.log(galleryImg);
          
          console.log(formDataa);
          
  
          post(API_URL.WHATSAPP_MESSAGE.POST_MESSAGE,formDataa)
          .then((response)=>{
            console.log(response);
            setEditorContent('')
            handleClose()
            toast.success('send',{autoClose:1000})
          }).catch((error)=>{
            console.error(error);
          })
      });
  };

  const categoryToggle = () => {
    setCategoryModal(!categoryModal);
    console.log('categoryToggle triggered', categoryModal); 
  };
  


  //selected row
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleRefresh = () => {
    fetchUsersList();
  };

  const handleCancel = () => {
    categoryToggle(); 
    setUserRole('');
    setGender('');
    setUsername('');
    setWhatsappNumber('');
    setCountry('');
    setState('');
    setUserCategories(''); 
    setData(originalData);
    setCsvData(originalData); 
};


  const handleOpen = () => {
    if(selectedRows.length > 0){
      setOpen(true);
    }else{
      alert("Please select users to message.");
    }
  }
  const handleClose = () => setOpen(false);
  

  useEffect(() => {
    fetchUsersList();
    fetchUserRoles();
  }, []);


  const handleSave = () => {
    
    const filteredData = originalData.filter(item => {
      const roleMatches = userRole ? String(item.user_role_id) === String(userRole) : true;
      const genderMatches = gender ? item.gender === gender : true;
        const usernameMatches = username ? (item.name ? item.name.includes(username) : false) : true;
        const PhonennumberMatches = whatsappNumber ? (item.phone_number ? item.phone_number.includes(whatsappNumber) : false) : true;
        console.log('Role Matches:', roleMatches); // Check if role matching works

        console.log('item',item);

        // const regionalPartnerMatches = selectedRegionalPartner ? item.uuid === userRegional.includes((i)=>{return i.user_uuid}) : true; 
        // const regionalPartnerMatches = selectedRegionalPartner ? item.uuid == selectedRegionalPartner : true;
        const regionalPartner = userRegional.find((partner) => partner.id == selectedRegionalPartner);
        const regionalPartnerUuid = regionalPartner ? regionalPartner.user_uuid : null;
    
        // Compare the item's uuid with the regional partner's uuid
        const regionalPartnerMatches = regionalPartnerUuid ? String(item.uuid) === String(regionalPartnerUuid) : true;
        console.log('regionalPartnerMatches',regionalPartnerMatches);
        console.log('regional id matches',regionalPartner);
        
        console.log('regionalPartnerUuid:', regionalPartnerUuid);
        console.log('item.uuid:', item.uuid);
        console.log('regionalPartnerMatches:', regionalPartnerMatches);
        // Fixing country and state filter comparison
        const countryMatches = country ? item.
        country_name === country : true;
        const cityMatches = cities.length > 0 ? cities.includes(item.city_id) : true;
        const stateMatches = state.length > 0 ? state.includes(item.state_id) : true;
        const categoryMatches = userCategories ? 
        String(item.category_id) === String(userCategories) : true;   
        console.log('this is usercategory type is',typeof userCategories);
        console.log('this is typeof item_category_id is',typeof item.category_id);
        
        
        // console.log('User Category from filter:',typeof userCategories);
        // console.log('Item category ID:',typeof item.category_id);
        
        return roleMatches && genderMatches && usernameMatches && PhonennumberMatches && countryMatches && stateMatches && categoryMatches && regionalPartnerMatches && cityMatches ;
    });

    setData(filteredData);
    setCsvData(filteredData); 
    categoryToggle(); 
    setUserRole('');
    setGender('');
    setUsername('');
    setWhatsappNumber('');
    setCountry('');
    setState('');
    setUserCategories(''); 
};

  return (
    <>
      <div className="d-flex align-items-center justify-content-between  p-4">
        <H4 attrH4={{ className: "text-muted m-0" }}> Users Details</H4>
        <div className="button-group">
          <div>
            <button
              type="button"
              className="btn mx-2"
              style={{
                width: "30px",
                height: "38px",
                padding: "0",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh " style={{ fontSize: "18px" }}></i>
            </button>
            <Btn attrBtn={{ color: 'primary', className: 'btn ms-2', onClick: categoryToggle }}><span className="title">Filters</span></Btn>

            <Btn
              attrBtn={{
                color: "primary",
                className: "btn ms-2",
                onClick:handleOpen
              }}
            >
              Send WhatsApp Message
            </Btn>
          </div>
          <EditCommonModal
						isOpen={categoryModal}
						toggle={categoryToggle}
						title="Apply filters"
						onSave={handleSave}
						onCancel={handleCancel}
					>
          <FilterForm
              userRole={userRole}
              userRoles={userRoles}
              setUserRole={setUserRole}
              gender={gender}
              setGender={setGender}
              username={username}
              setUsername={setUsername}
              whatsappNumber={whatsappNumber}
              setWhatsappNumber={setWhatsappNumber}
              country={country} 
              setCountry={setCountry}  
              state={state}  
              setState={setState} 
              city={city}  
              cities={cities}  
              setcities={setcities}  
              usercategories={userCategories}
              userCategory={userCategory}
              setUserCategories={setUserCategories}
              countries={countries}
              states={states}
              userRegional={userRegional} 
              selectedRegionalPartner={selectedRegionalPartner}
              setSelectedRegionalPartner={setSelectedRegionalPartner} 
          />
					</EditCommonModal>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter your text
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={editorContent}
              onChange={handleEditorChange}
              placeholder="Write something..."
            />
          </Typography>
          <Button onClick={bulkMessage}>Send Message</Button>
        </Box>
      </Modal>
      <div className="table-wrapper">
        <DataTable
          data={data}
          columns={notificationtableColumn(userRoles, sendMessage)}
          striped={true}
          center={true}
          pagination
          selectableRows={true}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleDelet}
        />
      </div>
      <div>
        <SavedMessages
        selectedRows={selectedRows}
        />
      </div>
    </>
  );
};

export default Notification;
