import { useLocation, useNavigate } from 'react-router';
import { Btn } from '../../../../AbstractElements';
import { CSVLink } from 'react-csv';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DELETE, get, post } from '../config';
import { API_URL } from '../config/api_urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import jsPDF from 'jspdf'; 
import 'jspdf-autotable'; 

function UserDetailsPage({ isOpen, onClose }) {
  const location = useLocation();

  const navigate = useNavigate()

  const videoRef = useRef(null)

  const { userData: user } = location.state || {}; 
  const [data, setData] = useState([]);
	const [csvData, setCsvData] = useState(data);
	const [gallery, setgallery] = useState(data);
  const [social, setsocial] = useState(data);
  const [profiles, setprofiles] = useState(data);
  const [attributes, setattributess] = useState([]);
  const [Userattributes, setUserattributess] = useState([]);
  const [userCategory, setuserCategory] = useState([]);
  const [videos, setvideos] = useState(data);
  const [userVideo,setUserVideo] = useState([])
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [profilePreferences, setProfilePreferences] = useState([]); 
  const [ProfileAttributes, setProfileAttributes] = useState([]);
  const [ProfileSocialmedia, setProfileSocialmedia] = useState([]);
  const [matchedAttributes, setMatchedAttributes] = useState([]);


    // setData(user)
    const userArray = Object.entries(user)

    console.log('user',user);
console.log('profile',profiles);

    
  useEffect(() => {
		setCsvData(userArray);		
	}, [])

  const handleExport = () => {
		setCsvData(userArray);
	}

  const handlePrint = () => {
    window.print();

  };

 

  
  const compareAttributes = useCallback(() => {
    if (Userattributes.length > 0 && attributes.length > 0) { 
      const matched = attributes
        .filter(attr => 
          Userattributes.some(userAttr => 
            attr.attribute_name === userAttr.attribute_name && userAttr.user_name === user.name
          )
        )
        .map(attr => {
          const matchedUserAttr = Userattributes.find(userAttr => 
            userAttr.attribute_name === attr.attribute_name && userAttr.user_name === user.name
          );
          
          return {
            ...attr,
            ...matchedUserAttr 
          };
        });
      
      setMatchedAttributes(matched);
    }
  }, [Userattributes, attributes, user.name]);

  
  // Fetch the necessary data and perform attribute comparison
  // useEffect(() => {
  //   if (user?.uuid) {
  //     fetchattributes();
  //     fetchProfile();
  //   }
  // }, [user, fetchattributes, fetchProfile]);
  
  const fetchCategory = useCallback(() => {
		get(API_URL.USER_CATEGORY.USER_CATEGORY_GET)
			.then((response) => {
				if (response?.data.length > 0)
					setuserCategory(response.data)
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}, []);

  console.log('usercategory',userCategory);
  const Category = userCategory?.find((i)=>i.id === user?.category_id)?.name 

console.log('category',Category);


  const fetchGallery = useCallback(() => { 
    get(`${API_URL.USERS.USER_GALLERY}`)
      .then((response) => {
        if (response?.data.length > 0) {
          setgallery(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gallery:", error);
      });
  }, [user.uuid]);
  
  const fetchsocial = useCallback(() => { 
    get(`${API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA}`)
    .then((response) => {
      if (response?.data.length > 0) {
        setsocial(response.data);
      }
    })
    .catch((error) => {
      console.error("Error fetching social media:", error);
    });
}, [user.uuid]);

const fetchvideos = useCallback(() => { 
  get(`${API_URL.PROFILE.GET_PROFILE}`)
  .then((response) => {
    if (response?.data.length > 0) {
      setvideos(response.data);
    }
  })
  .catch((error) => {
    console.error("Error fetching social media:", error);
  });
}, [user.uuid]);

// const allpreference = profilePreferences[0]
// console.log('outside',select);
const allpreference = Array.isArray(profilePreferences) && profilePreferences.length > 0 
  ? profilePreferences[0] 
  : null;  


const allsocialmedia = Array.isArray(ProfileSocialmedia) && ProfileSocialmedia.length > 0 
  ? ProfileSocialmedia[0] 
  : null; 


  const uservideomatches = userVideo.filter((video)=>video.user_uuid === user.uuid)

  

const fetchUserattributes = useCallback(() => { 
  get(API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES)
    .then((response) => {
      console.log(response.data);
      
      if (response?.data.length > 0) {
        setUserattributess(response.data);
        // setOriginalData(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);

const fetchattributes = useCallback(() => { 
  get(API_URL.ATTRIBUTES.GET_ATTRIBUTES)
    .then((response) => {
      console.log(response.data);
      
      if (response?.data.length > 0) {
        setattributess(response.data);
        // setOriginalData(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);


const fetchProfile = useCallback(() => { 
  get(API_URL.PROFILE.GET_PROFILE)
    .then((response) => {
      if (response?.data.length > 0) {
        setprofiles(response.data);
        const matchedProfiles = response.data.filter(profile => {
          // Log each profile during the filter process
          console.log("Checking profile:", profile);

          return profile.user_uuid === user.uuid;
        });

        
          
        if (matchedProfiles) {
          setProfilePreferences(matchedProfiles.map(profile => {
            
           return profile.preferences
          } )); 
          setProfileAttributes(matchedProfiles.map(profile => {
            
           return profile.attributes
          } ));

          setProfileSocialmedia(matchedProfiles.map(profile => {
            
           return profile.social_media
          } ));
        }
      }
    })
    .catch((error) => {
      console.error("Error fetching profile:", error);
    });
}, [user.uuid]);


if (ProfileAttributes && Array.isArray(ProfileAttributes)) {
  const profileMap = ProfileAttributes.map(({ Height, Weight }) => {
    
    return { Height, Weight };  // Create a new object with Height and Weight
  });

} else {
  console.error('ProfileAttributes is null or not an array');
}




const fetchEventVideo = useCallback(() => { 
  get(API_URL.USERS.USER_VIDEO)
    .then((response) => {
      console.log(response.data);
      
      if (response?.data.length > 0) {
        setUserVideo(response.data);
        // setOriginalData(response.data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}, []);

console.log('uservideo',userVideo);

const filteredImage = userVideo.filter(videos => videos.user_uuid === user.uuid)

console.log(filteredImage)

const handleAddVideo = (event)=>{
  const files = Array.from(event.target.files); 
  const names = files.map(file => file.name); 
  
  // setVideo(files);

  if(user.uuid){
    for(let i=0; i < files.length; i++){
      const formDataa = new FormData();
      formDataa.append('user_uuid',user.uuid);
      formDataa.append('video_file',files[i])
    
      post(API_URL.USERS.USER_VIDEO,formDataa)
      .then((response)=>{
        console.log(response);
        fetchEventVideo()
        toast.success("Image added succesfully")
        
      }).catch((error)=>{
        console.error(error);
        toast.error("Error adding image")					
      })
  }
  }

  }

  const handleMouseEnter = () => {
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    videoRef.current.pause();
  };

  const handleImageClick = () => {
		document.getElementById('file-input').click();
	  };

const handleDeleteGallary = (id)=>{
  console.log(id);
  
  if(id){
    DELETE(API_URL.USERS.DELETE_USERVIDEO(id))
    .then((response)=>{
      console.log(response);
      fetchEventVideo()
      toast.success('Deleted successfully')				
    }).catch((error)=>{
      console.error(error);
      
    })
  }
}
console.log('userattributes',Userattributes);

const navigateGallery = (uuid) => {
  console.log(uuid);
  
  navigate(`${process.env.PUBLIC_URL}/user/gallery/${uuid}`)
}
  

  useEffect(() => {
    if (user?.uuid) {
      fetchGallery();
      fetchsocial(); 
      fetchvideos()
      fetchEventVideo()
      fetchProfile()
      fetchattributes()
      fetchUserattributes()
      fetchCategory()
    }
  }, [fetchGallery, user]);

  useEffect(() => {
    compareAttributes(); // Compare attributes when profiles and attributes are fetched
  }, [attributes, ProfileAttributes, compareAttributes]);

  console.log('Matched Attributes:', matchedAttributes);

  const attributesvalues = matchedAttributes.map((i)=>{
    return i.enter_unit

  })

  console.log('log',matchedAttributes);
  

  if (!user) {
    return <p>No user data available</p>;
  }






  if (!user) {
    return <p>No user data available</p>;
  }

  const style = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)'

  }

  // const generatePDF = () => {
  //   const doc = new jsPDF();

  //   // Adding Title
  //   doc.setFontSize(16);
  //   doc.text("User Details", 20, 20);

  //   // Adding User Information
  //   doc.setFontSize(12);
  //   doc.text(Name: ${user.name || "N/A"}, 20, 30);
  //   doc.text(Gender: ${user.gender || "N/A"}, 20, 40);
  //   doc.text(DOB: ${user.date_of_birth || "N/A"}, 20, 50);
  //   doc.text(Age: ${user.age || "N/A"}, 20, 60);
  //   doc.text(Category: ${user.category || "N/A"}, 20, 70);

  //   // Adding Contact Information
  //   doc.text("Contact Information:", 20, 80);
  //   doc.text(Phone: ${user.phone_number || "N/A"}, 20, 90);
  //   doc.text(Email: ${user.email || "N/A"}, 20, 100);
  //   doc.text(WhatsApp: ${user.whatsapp_number || "N/A"}, 20, 110);

  //   // Adding Social Media Links
  //   if (ProfileSocialmedia.length > 0) {
  //     doc.text("Social Media:", 20, 120);
  //     ProfileSocialmedia.forEach((media, index) => {
  //       const mediaText = Object.entries(media)
  //         .map(([key, value]) => ${key}: ${value})
  //         .join(", ");
  //       doc.text(${mediaText}, 20, 130 + index * 10);
  //     });
  //   }

  //   // Adding Preferences
  //   doc.text("Preferences:", 20, 150);
  //   if (allpreference && allpreference.length > 0) {
  //     allpreference.forEach((pref, index) => {
  //       doc.text(${pref}, 20, 160 + index * 10);
  //     });
  //   } else {
  //     doc.text("N/A", 20, 160);
  //   }

  //   // Adding Attributes
  //   doc.text("Attributes:", 20, 180);
  //   if (ProfileAttributes && ProfileAttributes.length > 0) {
  //     ProfileAttributes.forEach((attr, index) => {
  //       const attrText = Object.entries(attr)
  //         .map(([key, value]) => ${key}: ${value})
  //         .join(", ");
  //       doc.text(${attrText}, 20, 190 + index * 10);
  //     });
  //   } else {
  //     doc.text("N/A", 20, 190);
  //   }

  //   // Save the PDF to a Blob
  //   const pdfBlob = doc.output("blob");

  //   // Create a new URL for the Blob
  //   const pdfUrl = URL.createObjectURL(pdfBlob);

  //   // Open the PDF in a new window
  //   const pdfWindow = window.open(pdfUrl);

  //   // After a short delay, trigger the print dialog
  //   setTimeout(() => {
  //     pdfWindow.print();
  //     pdfWindow.close();
  //   }, 1000);
  // };

  const Pdfstyle = {
    styles: `
      @media print {
        .hide-when-printing {
          display: none;
        }
      }
    `
  };
  


  return (
    <div className='container' style={{ padding: '20px',width:"100%",height:"100vh" }}>
   
      {/* <div className="w-100" style={{background:url(${user.cover_image}),backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
    <div className='row'>
       
      </div>

      <div className='row justify-content-center'>
        <div className='col-auto'>
          <div style={{
            width: '150px',
            height: '150px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <img
              src={user.profile_image}
              alt="User Profile"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                border: '1px solid #ddd',
                borderRadius: '50%',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                padding: '5px',
                transition: 'transform 0.2s',
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
              onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
            />
          </div>
          <div className='col-auto' >
          <h3 className='text-start' style={{color:"black",marginLeft:"40px"}}>
             {user.name}
          </h3>
          <h6 style={{color:"black",marginLeft:"40px"}}>{user.userRole}</h6>
          <h6 style={{color:"black",marginLeft:"50px"}}>{user.state_name}</h6>
        </div>
        <div className="m-3" style={{position:"relative",left:"450px",bottom:"200px"}}>
        <button className="btn btn-danger "  onClick={handlePrint}>
        <i class="fa-solid fa-file-pdf"></i>
                Download PDF
              </button>
        </div>
            
        </div>

      </div>
      <div className="d-flex align-items-center justify-content-center" style={{ width: "100%", height: "30px", backgroundColor: "" }}>
  {ProfileSocialmedia.length > 0 ? (
    <div className='' style={{ display: 'flex', gap: '10px'  }}>
     {ProfileSocialmedia.map((item, index) => (
  Object.entries(item).map(([key, value]) => {
    console.log('Key:', item, 'Value:', value); 
    return (
      <a 
        key={${index}-${key}} 
        href={value} 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ display: 'flex', alignItems: 'center',border:"1px solid pink", borderRadius:"5px" }}
      >
       {key.toLowerCase() === "instagram" && (
          <i className="fa-brands fa-instagram m-2" style={{ fontSize: "18px" }}></i>
         )}

       {key.toLowerCase() === "linkedin" && (
        <i className="fa-brands fa-linkedin m-2" style={{ fontSize: "18px" }}></i>
         )}
       {key.toLowerCase() === "facebook" && (
          <i className="fa-brands fa-facebook m-2" style={{ fontSize: "18px" }}></i>
        )}
        {key.toLowerCase() === "twitter" && (
         <i className="fa-brands fa-twitter m-2" style={{ fontSize: "18px" }}></i>
        )}
        {key.toLowerCase() === "others" && (
         <i className="fa-solid fa-hashtag m-2" style={{ fontSize: "18px" }}></i>
        )}

      </a>
    );
  })
))}

    </div>
  ) : (
    <span>No Social Media</span>
  )}
</div>


      </div>   */}
      <div className="w-100">
  <div className='row'>
    <img 
      src={user.cover_image} 
      alt="Cover Image" 
      style={{ 
        width: '100%', 
        height: '350px', 
        objectFit: 'cover' 
      }} 
    />
  </div>

  <div className='row justify-content-center' style={{position:"relative",top:"-300px"}}>
    <div className='col-auto'>
      <div style={{
        width: '150px',
        height: '150px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <img
          src={user.profile_image}
          alt="User Profile"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            border: '1px solid #ddd',
            borderRadius: '50%',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            padding: '5px',
            transition: 'transform 0.2s',
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
        />
      </div>

      <div className='col-auto'>
        <h3 className='text-start' style={{ color: "black", marginLeft: "40px" }}>
          {user.name}
        </h3>
        <h6 style={{ color: "black", marginLeft: "40px" }}>{user.userRole}</h6>
        <h6 style={{ color: "black", marginLeft: "50px" }}>{user.state_name}</h6>
      </div>

     
    </div>
  </div>

 

  <div className="d-flex align-items-center justify-content-center " style={{ width: "100%", height: "30px",position:"relative",top:"-250px" ,left:"-20px" }}>
    {ProfileSocialmedia.length > 0 ? (
      <div className='' style={{ display: 'flex', gap: '10px' }}>
        {ProfileSocialmedia.map((item, index) => (
          Object.entries(item).map(([key, value]) => {
            return (
              <a 
                key={`${index}-${key}`} 
                href={value} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ display: 'flex', alignItems: 'center', border: "1px solid pink", borderRadius: "5px" }}
              >
                {key.toLowerCase() === "instagram" && (
                  <i className="fa-brands fa-instagram m-2" style={{ fontSize: "18px" }}></i>
                )}
                {key.toLowerCase() === "linkedin" && (
                  <i className="fa-brands fa-linkedin m-2" style={{ fontSize: "18px" }}></i>
                )}
                {key.toLowerCase() === "facebook" && (
                  <i className="fa-brands fa-facebook m-2" style={{ fontSize: "18px" }}></i>
                )}
                {key.toLowerCase() === "twitter" && (
                  <i className="fa-brands fa-twitter m-2" style={{ fontSize: "18px" }}></i>
                )}
                {key.toLowerCase() === "others" && (
                  <i className="fa-solid fa-hashtag m-2" style={{ fontSize: "18px" }}></i>
                )}
              </a>
            );
          })
        ))}
      </div>
    ) : (
      <span>No Social Media</span>
    )}
  </div>
</div>


      <div className='row mt-4' style={{margin:"10px"}}>
        {/* <div className="" >
          <h5><i class="fa-solid fa-id-card m-3"></i>BASIC INFO</h5>
          <div className=""style={{marginLeft:"15px"}}>
          <p><strong>Name :</strong>{user.name}</p>
          <p><strong>Gender :</strong>{user.gender}</p>
          <p><strong>DOB :</strong>{user.date_of_birth}</p>
          <p><strong>Age :</strong>{user.age}</p>
          <p><strong>Category :</strong>{Category}</p>
          </div>
         

        </div> */}
        <div className="" style={{position:"relative",top:"-250px"}}>
  <h5><i className="fa-solid fa-id-card m-3"></i>BASIC INFO</h5>
  <div className="" style={{ marginLeft: "15px" }}>
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {/* First Row */}
      <div style={{ flex: "0 0 48%" }}>
        <p><strong>Name :</strong> {user.name}</p>
      </div>
      <div style={{ flex: "0 0 48%" }}>
        <p><strong>Gender :</strong> {user.gender}</p>
      </div>
      
      {/* Second Row */}
      <div style={{ flex: "0 0 48%" }}>
        <p><strong>DOB :</strong> {user.date_of_birth}</p>
      </div>
      <div style={{ flex: "0 0 48%" }}>
        <p><strong>Age :</strong> {user.age}</p>
      </div>

      {/* Last Row */}
      <div style={{ flex: "0 0 100%" }}>
        <p><strong>Category :</strong> {Category}</p>
      </div>
        </div>
       </div>
       <div className="m-3" style={{ position: "relative", left: "80%", top: "-200px" }}>
      <style dangerouslySetInnerHTML={{ __html: Pdfstyle.styles }} />
     <button className="btn btn-danger hide-when-printing" onClick={handlePrint}>
      <i className="fa-solid fa-file-pdf"></i> Download PDF
    </button>
      </div>

     </div>

        <div className="" style={{position:"relative",top:"-300px"}}>
          <h5><i class="fa-solid fa-square-phone m-3"></i>CONTACT ME</h5>
        <div className="d-flex flex-row m-3" style={{gap:"20px" }}>
      <div className='col-4 rounded ' style={style}>
         
         <p style={{marginLeft:"0px",marginTop:"10px"}}><i class="fa-solid fa-phone"></i> {user.phone_number|| 'N/A'}</p>
       </div>
       <div className='col-4  rounded'  style={style}>
         <p style={{marginLeft:"0px",marginTop:"10px"}}><i class="fa-solid fa-envelope"></i> {user.email || 'N/A'}</p>
       </div>
       <div className='col-4  rounded'  style={style}>
         <p style={{marginLeft:"0px",marginTop:"10px"}}><i class="fa-brands fa-whatsapp"></i> {user.whatsapp_number || 'N/A'}</p>
       </div>

            </div>
        </div>

     
    
      
     

    
        <div className="" style={{marginTop:"-300px"}} >
          <h5><i class="fa-regular fa-file-lines m-3" ></i>ABOUT ME</h5>
          <p style={{marginLeft:"20px"}}>{user.about}</p>
        </div>
       
       
        <div className="d-flex flex-row m-3" style={{gap:"20px" }}>

      
        </div>
        <div className="" style={{ marginTop: "-200px" }}>
  <h5>
    <i className="fa-solid fa-trophy m-3"></i>
    MY ACHIEVEMENTS
  </h5>
  <p style={{ marginLeft: "20px" }}>
    {user.awards_achievements ? (
      <button
        style={{
          margin: "5px",
          padding: "5px 10px",
          borderRadius: "5px",
          backgroundColor: "",
          color: "black",
          border: "1px solid pink",
          cursor: "pointer",
        }}
        disabled
      >
        {user.awards_achievements}
      </button>
    ) : (
      <p >No achievements available</p>
    )}
  </p>
</div>

        <div className="" style={{marginTop:"-100px"}}>
  <h5><i className="fa-solid fa-mug-hot m-3"></i>MY PREFERENCES</h5>
  <div className='col-6 rounded' style={style}>
    {allpreference && allpreference.length > 0 ? (
      <div style={{ marginLeft: "20px" }}>
        {allpreference.map((pref, index) => (
          <button 
            key={index} 
            style={{ 
              margin: "5px", 
              padding: "5px 10px", 
              borderRadius: "5px", 
              backgroundColor: "", 
              color: "black", 
              border: "1px solid pink", 
              cursor: "pointer" 
            }}
            disabled
          >
            {pref}
          </button>
        ))}
      </div>
    ) : (
      <p style={{ marginLeft: "20px" }}>N/A</p>
    )}
  </div>
</div>



<div className="">
  <h5><i className="fa-brands fa-creative-commons-by m-3"></i>MY ATTRIBUTE</h5>
  <div className='col-6 rounded' style={style}>
    {matchedAttributes && matchedAttributes.length > 0 ? (
      <div style={{ marginLeft: "20px" }}>
        {matchedAttributes.map((attr, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
              <button 
                // key={key} 
                style={{ 
                  margin: "5px", 
                  padding: "5px 10px", 
                  borderRadius: "5px", 
                  backgroundColor: "", 
                  color: "black", 
                  border: "1px solid pink", 
                  cursor: "pointer" 
                }}
                disabled
              >

                {attr.attribute_name}:{attr.value} {attr.enter_unit}
              </button>
          </div>
        ))}
      </div>
    ) : (
      <p style={{ marginLeft: "20px" }}>N/A</p>
    )}
  </div>
</div>


     <div className='row mt-4' style={{ margin: "10px"}}>
  <div className="" style={{marginTop:"-20px"}}>
    <h5><i className="fa-solid fa-images m-3"></i>GALLERY</h5>
      <Link to={`${process.env.PUBLIC_URL}/user/gallery/${user.uuid}`}>{`${window.location.origin}/user/gallery/${user.uuid}`}</Link>
  </div>  

  {/* <div className="mt-5" >
        <h5><i className="fa-solid fa-film"></i> VIDEO LINKS</h5>
         {userVideo.length > 0 ? (
          <div className="video-links" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {filteredImage?.length>=0 && filteredImage.map((video,index)=>(
          
          <div key={index} className='image-container' onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
          	<video ref={videoRef} onMouseEnter={handleMouseLeave} onMouseLeave={handleMouseEnter} autoPlay
				className='image'
	            src={video.video_file}
	            alt={event-video-${index}}
	            style={{ height: '200px', objectFit: 'cover', borderRadius: '4px', }}
	    	/>
			{hoveredIndex === index && (
				<button onClick={()=>handleDeleteGallary(video.id)} className='hover-button'><FontAwesomeIcon icon={faTrash} /></button>)}
          </div>
			  
        ))}
        <div style={{border:"1px dotted"}} className='d-flex flex-column align-items-center justify-content-center p-3 rounded'>
			<input id='file-input' type="file" style={{display:"none"}} onChange={handleAddVideo}/>
			<div className='d-flex flex-column align-items-center justify-content-center' onClick={handleImageClick} style={{cursor:'pointer'}}>
				<p>Add Video</p>
				<FontAwesomeIcon icon={faPlus} />
			</div>
		</div>
          </div>
        ) : (
           <p>No videos available.</p>
        )} 

      </div> */}
      <div className="mt-5">
        <h5><i className="fa-solid fa-film"></i> VIDEO LINKS</h5>
        {uservideomatches.length > 0 ? (
          <div className="video-links" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {uservideomatches.map((video, index) => (
              <div key={index} style={{ flex: '1 0 100%', marginBottom: '10px' }}>
                <a href={video.video_file} target="_blank" rel="noopener noreferrer">
                  <i className="fa-solid fa-video" style={{ fontSize: "18px", marginRight: "5px" }}></i>
                  Watch Video {index + 1}
                </a>
              </div>
            ))}
          </div>
        ) : (
          <p>No videos available.</p>
        )}
      </div>
    {/* </div> */}
         {/* <div className="row">
          <div className="col text-center mt-4">
          <Link to={${process.env.PUBLIC_URL}/user/videolinks/${user.uuid}}>{${window.location.origin}/user/videolinks/${user.uuid}}</Link>

              <button className="btn btn-danger" onClick={handlePrint}>
                Download PDF
              </button>
          
          </div>
        </div> */}
      

</div>



      </div>
    </div>
  );
}

export default UserDetailsPage;