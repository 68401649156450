import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import EditCommonModal from '../common/EditCommonModal';

function NewSocialmedia({ editPreferenceData, isOpen, toggle, title, onSave, onCancel }) {

  const [isEditing, setIsEditing] = useState(false);
  const [formDataConfig, setFormDataConfig] = useState({
    initialState: {
      id: null,
      name: ''
    },
    fields: [
      {
        name: 'name',
        label: 'Social Media',
        type: 'select', 
        options: [ 
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'linkedin', label: 'LinkedIn' },
            { value: 'Twitter', label: 'Twitter' },
            { value: 'others', label: 'Others' }
        ],
        required: true,
      }
    ],
  });

  useEffect(() => {
    if (editPreferenceData) {
      setIsEditing(true);
      setFormDataConfig(value => ({
        ...value,
        initialState: editPreferenceData,
      }));
    } else {
      setIsEditing(false);
      setFormDataConfig(value => ({
        ...value,
        initialState: {
          id: null,
          name: '',
        },
      }));
    }
  }, [editPreferenceData]);

  const handleSave = () => {
    onSave(formDataConfig.initialState);
  };

  const handleCancel = () => {
    onCancel();
  };

  const setSocialMedia = (e) => {
    setFormDataConfig({
      ...formDataConfig,
      initialState: {
        ...formDataConfig.initialState,
        name: e.target.value
      }
    });
  };

  return (
    <EditCommonModal
      isOpen={isOpen}
      toggle={toggle}
      title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <div className='mb-4'>
        <FormGroup>
          <Label className="col-form-label pt-0">Social Media</Label>
          <Input
            type="select"
            value={formDataConfig.initialState.name}
            onChange={setSocialMedia}
            className="form-control"
          >
            <option value="">Select Social Media</option> 
            {formDataConfig.fields[0].options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>      
          </FormGroup>
      </div>
    </EditCommonModal>
  );
}

export default NewSocialmedia;
