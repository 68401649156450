import { Checkbox } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';


function IdcardTerms() {

    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNavigateToIdCards = () => {
        navigate(`${process.env.PUBLIC_URL}/id-card/:layout`)
      }

    const handleNavigate = ()=>{
        handleNavigateToIdCards();
        handleClose();
    }

    const label = { input: { "terms_accepted" :"yes" } };

    console.log(label);

    // const fetchMembership = useCallback(async ()=>{
    //     try {
    //         const response = await get(API_URL.IDCARDS.GET_MEMBERSHIP)

    //         const data = response.data;
    //         console.log(data);
            
    //         const lastObject = data[data.length - 1];
    //         console.log(lastObject);
            
         

    //     } catch(error){
    //         console.log("error:",error);
            
    //     }
    // })

    // const onSave = (formData) => {

    //     const values = {
    //         membership_uuid :"5508849d-97cf-436f-b6e2-5aededce6f2e",
    //         user_uuid :"9b024045-858c-413a-9186-ae9b85069833",
    //         terms_accepted :label.input.terms_accepted
    //     }

    //       post(API_URL.IDCARDS.POST_IDCARD, values)
    //         .then((response) => {
    //           toast.success("Membership created successfully");
    //           handleNavigateToTerms();
              
    //           // handleNavigateToIdCards();
    //         })
    //         .catch((error) => {
    //           console.error("Error saving data:", error);
    //           toast.error("Oops! something went wrong, try again");
    //         });
       
    //   };
    

    useEffect(()=>{
        handleShow()
        // fetchMembership()
    },[])

  return (
    <div>
        <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body><Checkbox {...label} defaultChecked />Accept Payment Terms</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleNavigate}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleNavigate}>
                  Accept
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
    </div>
  )
}

export default IdcardTerms