import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { jobopeningstabledata, jobopeningstableColumns } from '../common/mockData';
import { Btn, H4 } from '../../../../AbstractElements';
import { useNavigate } from 'react-router';
import { Input } from 'reactstrap';
import { Search } from 'react-feather';
import { API_URL } from '../config/api_urls';
import { DELETE, get, post } from '../config';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditCommonModal from '../common/EditCommonModal';
import FilterForm from '../users/FilterForm';
import ConfirmationModal from '../common/Confiemationdeletemodal'; 


function EventOpenings() { 

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editJobOpening,setEditJobOpening] = useState();
  const [eventCategory, setEventCategory] = useState();
  const [eventTypes,setEventTypes] = useState();
  const [states,setStates] = useState();
  const [countries,setCountries] = useState();
  const [cities,setCities] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [rowToDelete, setRowToDelete] = useState(null);

  const [userData,setUserData] = useState([])

  const [modalShow,setModalShow] = useState(false)
  const [uuid,setUuid] = useState('')
  const [eventVideo,setEventVideo] = useState([])

  //userdata
  const [userPreferences, setUserPreferences] = useState([]); 
  const [userAttributes, setUserAttributes] = useState([]);
  const [userSocial, setUserSocial] = useState([]);
  const [uservideo, setUservideo] = useState([]);
  const [userabout, setUserabout] = useState([]);

   //filter
   const [originalData,setOriginalData] = useState([])
   const [csvData, setCsvData] = useState(data);
   const [userRegional, setUserRegional] = useState([]);
   const [userRole, setUserRole] = useState();
   const [gender, setGender] = useState('');
   const [username, setUsername] = useState(''); 
   const [whatsappNumber, setWhatsappNumber] = useState('');
   const [country, setCountry] = useState('');
   const [state, setState] = useState('');
   const [selectedRegionalPartner, setSelectedRegionalPartner] = useState('');
   const [userCategories, setUserCategories] = useState();
   const [categoryModal, setCategoryModal] = useState(false);const [userRoles, setUserRoles] = useState([]);
   const [city,setcity] = useState([]);



  
  //modal
	function MyVerticallyCenteredModal({uuid, ...props}) {
    const [video,setVideo] = useState([])
	const [hoveredIndex, setHoveredIndex] = useState(null);




    const fetchEventVideo = useCallback(() => { 
      get(API_URL.EVENTS.VIDEO_LINKS)
        .then((response) => {
          console.log(response.data);
          
          if (response?.data.length > 0) {
            setVideo(response.data);
            // setOriginalData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);

    console.log(video);

    const filteredImage = video.filter(videos => videos.event_uuid_read === uuid)

    console.log(filteredImage)
	// setVideoImage(filteredImage)

    const handleAddGallery = (event)=>{
		const files = Array.from(event.target.files); // convert FileList to an array
		const names = files.map(file => file.name); // get the file names
		
		// setVideo(files);

		if(uuid){
			for(let i=0; i < files.length; i++){
				const formDataa = new FormData();
				formDataa.append('event_uuid',uuid);
        formDataa.append('video_file',files[i])
			
				post(API_URL.EVENTS.VIDEO_LINKS,formDataa)
				.then((response)=>{
					console.log(response);
					fetchEventVideo()
					toast.success("Image added succesfully",{autoClose:1000})
					
				}).catch((error)=>{
					console.error(error);
					toast.error("Error adding image",{autoClose:1000})					
				})
		}
	  }

    }

	const handleImageClick = () => {
		document.getElementById('file-input').click();
	  };

	
	const handleDeleteGallary = (id)=>{
		console.log(id);
		
		if(id){
			DELETE(API_URL.EVENTS.DELETE_VIDEOLINK(id))
			.then((response)=>{
				console.log(response);
				fetchEventVideo()
				toast.success('Deleted successfully',{autoClose:1000})				
			}).catch((error)=>{
				console.error(error);
				
			})
		}
	}
    
  
  

    useEffect(()=>{
      fetchEventVideo()        	
    },[setVideo])

		return (
		  <Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter" 
			centered
		  >
			<Modal.Header className='mt-2' closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Event Video
			  </Modal.Title>
			  {/* <Button onClick={handleSaveGallery}>Save Gallery</Button> */}
			</Modal.Header>
			<Modal.Body>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
			  {filteredImage?.length>=0 && filteredImage.map((video,index)=>(
          
          <div key={index} className='image-container' onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
          	<video autoPlay
				className='image'
	            src={video.video_file}
	            alt={`event-video-${index}`}
	            style={{ height: '200px', objectFit: 'cover', borderRadius: '4px', }}
	    	/>
			{hoveredIndex === index && (
				<button onClick={()=>handleDeleteGallary(video.id)} className='hover-button'><FontAwesomeIcon icon={faTrash} /></button>)}
          </div>
			  
        ))}
		<div style={{border:"1px dotted"}} className='d-flex flex-column align-items-center justify-content-center p-3 rounded'>
			<input id='file-input' type="file" style={{display:"none"}} onChange={handleAddGallery}/>
			<div className='d-flex flex-column align-items-center justify-content-center' onClick={handleImageClick} style={{cursor:'pointer'}}>
				<p>Add Video</p>
				<FontAwesomeIcon icon={faPlus} />
			</div>
		</div>
        </div>
		

			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		  </Modal>
		);
	  }

    const handleImageClick = (row) => {
      setEventVideo(row); // Set the clicked row's data
      setUuid(row.uuid) ;    
      setModalShow(true);   // Show the modal
    };

    console.log(uuid);
    
    console.log(data);
    

  useEffect(() => {
    fetchEvents();
    fetchEventCategory();
    fetchEventTypes();
    fetchStates();
    fetchCountries();
    fetchCities();
    fetchUsersList();
  }, [])
  
  const fetchEvents = useCallback(() => {
    get(API_URL.EVENTS.GET_EVENTS)
      .then((response) => {
        if (response?.data.length > 0)
          console.log(response.data);
          
          setData(response.data);
          setOriginalData(response.data);
          
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  const handleProfileResponse = (profilesResponse) => {
    const profiles = profilesResponse?.data || [];
    console.log("Fetched User seprate Preferences:", profiles); 
    setUserPreferences(profiles); 
  };

  const handleAttributesResponse = (attributesResponse) => {
    const attributes = attributesResponse?.data || [];
    console.log("Fetched User Attributes:", attributes); 
    setUserAttributes(attributes); 
  };

  const handleSocialmediaResponse = (SocialResponse) => {
    const socialmedia = SocialResponse?.data || [];
    console.log("Fetched User Social Media:", socialmedia); 
    setUserSocial(socialmedia); 
  };

  const handleUservideoResponse = (videoResponse) => {
    const Uservideo = videoResponse?.data || [];
    console.log("Fetched User Video Media:", Uservideo); 
    setUservideo(Uservideo);
};

const handleUseraboutResponse = (aboutResponse) => {
  const Userabout = aboutResponse?.data || [];
  console.log("Fetched User Video Media:", Userabout); 
  setUserabout(Userabout);
};

  const fetchUsersList = useCallback(async() => { 
    try {

      const usersResponse = await get(API_URL.USERS.GET_USERS);
      const users = usersResponse?.data || [];
      console.log("Fetched Users:", users); 

      // setOriginalData(users);
      setUserData(users);


      const profilesResponse = await get(API_URL.PROFILE_PREFERENCES.GET_PREFERENCES); 
      const profiles = profilesResponse?.data || [];
      handleProfileResponse(profilesResponse);
      

      const attributesResponse = await get(API_URL.USER_ATTRIBUTES.GET_USER_ATTRIBUTES);
      const attributes = attributesResponse?.data || [];
      handleAttributesResponse(attributesResponse);

      const socialmediaResponse = await get(API_URL.SOCIAL_MEDIA.GET_SOCIAL_MEDIA); 
      const socialmedia = socialmediaResponse?.data || [];
      handleSocialmediaResponse(socialmediaResponse);

      const videoResponse = await get(API_URL.PROFILE.GET_PROFILE); 
      const Uservideo = videoResponse?.data || [];
      handleUservideoResponse(videoResponse);
      console.log('Video link',videoResponse);

      
      const useraboutResponse = await get(API_URL.PROFILE.GET_PROFILE); 
      const about = useraboutResponse?.data || [];
      handleUseraboutResponse(useraboutResponse);

      console.log("Fetched User Preferences:", profiles);
      setUserData(users); 
      setUserPreferences(profiles); 
    } catch (error) {
      console.error("Error fetching users or preferences:", error);
    } 
	}, []);

  const enrichedData = userData.map((user) => {
    const preference = userPreferences.find((pref) => pref.user_name === user.name);
    const attribute = userAttributes.find((attr) => attr.user_name === user.name);
    const socialmedia = userSocial.find((media) => media.user_name === user.name);
    const Videolink = uservideo.find((video) => video.user_name === user.name); 
    const About = userabout.find((about) => about.user_name === user.name); 

    // console.log('chek this',uservideo);
    
    const videoLinks = Videolink?.video_links || []; // Get the video_link array
    const firstVideoLink = videoLinks.length > 0 ? videoLinks[0] : null; 
    // console.log('this is video',videoLinks);

    return {
        ...user,
        preference: preference?.preference_name || 'No Preference',
        attribute: attribute?.attribute_name || 'No Attribute', 
        media: socialmedia?.link || null,
        Video: firstVideoLink || 'No Video', 
        about: About?.about || 'No About', 

    };
});
  

  const fetchEventCategory = useCallback(() => {
    get(API_URL.EVENT_CATEGORY.GET_EVENT_CATEGORY)
      .then((response) => {
        if (response?.data.length > 0)
          setEventCategory(response.data)
          setUserCategories(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchEventTypes = useCallback(() => {
    get(API_URL.EVENT_TYPES.GET_EVENT_TYPES)
      .then((response) => {
        if (response?.data.length > 0)
          setEventTypes(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const fetchCities = useCallback(() => {
    get(API_URL.CITIES.CITIES_GET)
      .then((response) => {
        // console.log("response ", response);
        if (response?.data.length > 0)
          // setCities(response.data)
          setcity(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  console.log(city);
  

  const fetchStates = useCallback(() => {
    get(API_URL.STATES.STATES_GET)
      .then((response) => {
        if (response?.data.length > 0)
          setStates(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  const fetchCountries = useCallback(() => {
    get(API_URL.COUNTRIES.COUNTRIES_GET)
      .then((response) => {
        if (response?.data.length > 0)
          setCountries(response.data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  // const fetchcity = useCallback(async () => {
  //   try {
  //     const response = await get(API_URL.CITIES.CITIES_GET);
  //     const city = response?.data?.map((i) => ({
  //       label: i.name,
  //       value: i.id,
  //     }));
  //     city.unshift({ label: "Select city", value: "" });
  //     if (city.length > 0) {
  //       setFormDataConfig((prevConfig) => ({
  //         ...prevConfig,
  //         fields: prevConfig.fields.map((field) =>
  //           field.name === "city_id" ? { ...field, options: city } : field
  //         ),
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }, []);

  const navigateApplication = (row) => {
    console.log(row);
    
    navigate(`${process.env.PUBLIC_URL}/events/application-count/${row.uuid}`)
  }


  const handleNavigate = () => {
    navigate(`${process.env.PUBLIC_URL}/events/openings/new/:layout`);
  };

  const handleEditdata =(row)=>{
    console.log(row);
    
    setEditJobOpening(row)
    }

    const handleApplicationCount = (row)=>{
      navigateApplication(row)
    }


  useEffect(() => {
      if (editJobOpening) {
        navigate(`${process.env.PUBLIC_URL}/events/openings/new/:layout`, { state: { jobOpeningsData: editJobOpening } });
      }
    }, [editJobOpening, navigate]);  


  const handleInput = event => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    const filteredData = searchValue
      ? data.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()))
      : data;
    setData(filteredData);
  };

  const handleRefresh = () => {
    setSearchValue(''); 
    fetchEvents();
  };

  const handleCancel = () => {
    categoryToggle(); 
    setUserRole('');
    setGender('');
    setUsername('');
    setWhatsappNumber('');
    setCountry('');
    setCities('');
    setState('');
    setUserCategories(''); 
    setData(originalData);
    setCsvData(originalData); 
};

const handleDeleteConfirmation = (row) => {
  setRowToDelete(row);
  setIsDeleteModalOpen(true); 
};

const handleConfirmDelete = () => {
  setIsDeleteModalOpen(false); 
  handleDeletedata(rowToDelete);
};


  const handleDeletedata = (row)=>{
    if(row?.uuid){
     DELETE(API_URL.EVENTS.DELETE_EVENT(row?.uuid))
     .then((response) => {
       toast.success("Deleted successfully",{autoClose:1000});
       fetchEvents();
     })
     .catch((error) => {
       toast.error("Oops! something went wrong",{autoClose:1000});
     });
    }
   }

   const categoryToggle = () => {
		setCategoryModal(!categoryModal);
	};

  console.log(originalData);
  console.log(state);
  

  console.log(enrichedData);
  
  const handleUser = (row)=>{
    console.log(row);

    const filteredUser = enrichedData.filter(user => user.uuid === row.user_uuid)

    console.log(enrichedData);
    

    // const userRole = userRoles.find(role => role.id === row.user_role_id)?.role || 'No Role';

    // const enrichedUser = {
    //   ...row,
    //   userRole, 
    //   // Add userRole to the user object
    // };

    navigate(`/users/usersdetails/${row.user_uuid}`,{state:{userData: filteredUser[0]}});
  }
  

   //filter
   const handleSave = () => {

    const filteredData = originalData.filter(item => {
      const roleMatches = userRole ? String(item.user_role_id) === String(userRole) : true;
      const genderMatches = gender ? item.gender === gender : true;
        const usernameMatches = username ? (item.name ? item.name.includes(username) : false) : true;
        const PhonennumberMatches = whatsappNumber ? (item.phone_number ? item.phone_number.includes(whatsappNumber) : false) : true;
        console.log('Role Matches:', roleMatches); // Check if role matching works

        console.log('This ite item',item);

        const regionalPartnerMatches = selectedRegionalPartner ? item.uuid === selectedRegionalPartner : true; 
        // Fixing country and state filter comparison
        const countryMatches = country ? item.
        country_name === country : true;
        const cityMatches = cities.length > 0 ? cities.includes(item.city) : true;
        const stateMatches = state ? item.
        state === state : true;
        const categoryMatches = userCategories ? 
        String(item.category) === String(userCategories) : true;   
        console.log('this is usercategory type is',typeof userCategories);
        console.log('this is typeof item_category_id is',typeof item.category_id);
        console.log('');
        
        
        // console.log('User Category from filter:',typeof userCategories);
        // console.log('Item category ID:',typeof item.category_id);
        
        return roleMatches && genderMatches && usernameMatches && PhonennumberMatches && countryMatches && stateMatches && categoryMatches && regionalPartnerMatches && cityMatches ;
    });

    console.log(filteredData);
    

    setData(filteredData);
    setCsvData(filteredData); 
    categoryToggle();  
    setUserRole('');
    setGender('');
    setUsername('');
    setWhatsappNumber('');
    setCountry('');
    setCities('')
    setState('');
    setUserCategories(''); 
};




  return (
    <>
      <div>
        <div className={`d-flex align-items-center justify-content-between p-4`}>
          <H4 attrH4={{ className: 'text-muted m-0' }}>Event Openings</H4>
          <div className='d-flex'>
            <Btn attrBtn={{ color: 'primary', onClick: handleNavigate }}>+ Add Event Openings</Btn>
            <Btn attrBtn={{ color: 'primary', className: 'btn ms-2', onClick: categoryToggle }}><span className="title">Filters</span></Btn>
  
            <EditCommonModal
  						isOpen={categoryModal}
  						toggle={categoryToggle}
  						title="Apply filters"
  						onSave={handleSave}
  						onCancel={handleCancel}
  					>
            <FilterForm
                userRole={userRole}
                userRoles={userRoles}
                setUserRole={setUserRole}
                gender={gender}
                setGender={setGender}
                username={username}
                setUsername={setUsername}
                whatsappNumber={whatsappNumber}
                setWhatsappNumber={setWhatsappNumber}
                country={country} 
                setCountry={setCountry}  
                state={state}  
                setState={setState} 
                city={city}  
                cities={cities}  
                setcities={setCities}  
                usercategories={userCategories}
                userCategory={eventCategory}
                setUserCategories={setUserCategories}
                countries={countries}
                states={states}
                userRegional={userRegional} 
                selectedRegionalPartner={selectedRegionalPartner}
                setSelectedRegionalPartner={setSelectedRegionalPartner} 
            />
  					</EditCommonModal>
            
            <div className="faq-form ms-2 d-flex align-items-center">
              <Input className="form-control" type="text" placeholder="Search Event" value={searchValue} onChange={handleInput} />
              <div className="search-icon-container ms-2">
              <Search className="search-icon" onClick={handleSearch} /> 
              </div>
            </div>
            <button type='button' className='btn' style={{width:'30px',height:'38px',padding:'0', borderRadius:'10px',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleRefresh}><i className="fa fa-refresh " style={{fontSize:'18px'}}></i></button>
          </div>
        </div>
        <div>
          <DataTable
            data={data}
            columns={jobopeningstableColumns(handleEditdata,handleDeleteConfirmation,eventCategory,eventTypes,states,countries,city,handleApplicationCount,handleImageClick,handleUser)}
            striped={true}
            center={true}
            pagination
            selectableRows={false}
            clearSelectedRows={toggleDelet}
          />
        </div>
  
        <MyVerticallyCenteredModal
  
          show={modalShow}
          onHide={() => setModalShow(false)}
          galleryImages={eventVideo}
          uuid={uuid}
  
        /> 
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        onConfirm={handleConfirmDelete}
        message="Are you sure to delete?"
      />
    </>
  );
}

export default EventOpenings;
