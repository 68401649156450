import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../config'; // Replace with actual API client
import { API_URL } from '../config/api_urls';
import './idcardprofile.css'; // External CSS for ProfilePage
import ImodaIcon from '../../../../assets/images/Imoda-logo.svg';
import Idcard1 from '../../../../assets/images/Idcards/IdcardBottom.jpg';
import IDcard2 from '../../../../assets/images/Idcards/idcardbottom2.jpg';

//Imoda_logo.svg

const ProfilePage = () => {
  const { id } = useParams();
  const [profileData, setProfileData] = useState(null); 



  useEffect(()=>{
    fetchIdCardList();
  },[])

  const handlePrint = () => {
    window.print();

  };


  const fetchIdCardList = useCallback(() => {
    get(API_URL.IDCARDS.GET_IDCARDS)
      .then((response) => {
        console.log('response',response);
        
        const filteredProfile = response.data.find(profile => {
            const isMatch = profile.id == id; 
            console.log(`Checking profile ID: ${profile.id}, against param ID: ${id}, Match: ${isMatch}`); 
            return isMatch; 
          });
        setProfileData(filteredProfile || {}); 
        console.log('filtered',filteredProfile);
        
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [id]);


  console.log('profiledata',profileData);
  console.log('id',id);
  

  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page" style={{border:"1px solid"}}>
<div className="" style={{width:"90%",height:"170px",borderRadius:"5px",border:"",position:"relative",top:"-10px",left:"60px"}}>
<div className="" style={{position:"relative",top:"-30px",left:"220px"}}>
<img src={ImodaIcon} alt=""  height={"150px"} width={"200px"}/>
<h3 style={{position:"relative",top:"-50px",left:"-20px"}}>Member Of Imoda</h3>
<button className="hide-when-printing bg-danger" style={{position:"relative",left:"300px",top:"-100px",width:"130px",height:"30px",borderRadius:"3px"}} onClick={handlePrint}>Print</button>
    </div>
</div>
      <div className="profile-header mt-3">
        <div style={{
          width: '150px',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img
            src={profileData.image}
            alt="User Profile"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              border: '1px solid #ddd',
              borderRadius: '50%',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              padding: '5px',
              transition: 'transform 0.2s',
            }}
            // onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
            // onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
          />
        </div>
        <div className="profile-info">
          <h5>{profileData.name}</h5>
          <p className="user-role">{profileData.user_role_name}</p>
        </div>
      </div>
      <div className="profile-details" style={{position:"relative",left:"100px"}}>
      <div className="detail-row">
        <p><strong>ID </strong> <span style={{position:"relative",left:"177px"}}><strong>:</strong></span></p>
          <p style={{position:"relative",left:"-150px"}}><strong>{profileData.id_card_number }</strong> </p>
        </div>
        <div className="detail-row">
          <p><strong>Date of Birth </strong><span style={{position:"relative",left:"100px"}}><strong>:</strong></span> </p>
          <p style={{position:"relative",left:"-150px"}}><strong>{profileData.dob}</strong>  </p>

        </div>
        <div className="detail-row">
        <p><strong>Mobile </strong> <span style={{position:"relative",left:"145px"}}><strong>:</strong></span></p>
          <p style={{position:"relative",left:"-150px"}}><strong>{profileData.phone_number}</strong>  </p>
        </div>
        <div className="detail-row">
          <p><strong>Email </strong><span style={{position:"relative",left:"157px"}}><strong>:</strong></span> </p>
          <p style={{position:"relative",left:"-150px"}}><strong>{profileData.email}</strong>  </p>

        </div>
        <div className="detail-row">
          <p><strong>Validity Range </strong> <span style={{position:"relative",left:"85px"}}><strong>:</strong></span></p>
          <p style={{position:"relative",left:"-150px"}}><strong>{profileData.validity_range}</strong> </p>

        </div>
      </div>
      {/* <div className="profile-images" style={{position:"relative",left:"50px"}}>
        <div style={{position:"relative",left:"60px"}}>
          <h6>Aadhaar Front Side</h6>
          <img src={profileData.aadhar_front_image} alt="Aadhaar Front" className="profile-image" />
        </div>
        <div style={{position:"relative",left:"-170px"}}>
          <h6>Aadhaar Back Side</h6>
          <img src={profileData.aadhar_back_image} alt="Aadhaar Back" className="profile-image" />
        </div>

      
      </div> */}
      <div style={{
          width: '150px',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position:"relative",
          left:"570px",
          top:"-180px"
        }}>
          <img
            src={profileData.qr_code_image}
            alt="User Profile"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              border: '1px solid #ddd',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              padding: '5px',
              transition: 'transform 0.2s',
            }}
            // onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
            // onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
          />
        </div>
        <div className="" style={{width:"90%",height:"130px",borderRadius:"5px",border:"2px solid pink",position:"relative",top:"-130px",left:"40px"}}>
        <div className="d-flex align-items-center justify-content-center mt-4">
        <h6>Approved By Govt. of India <br /> Minsitry of Corporate Affairs <br /> Associated with AIFA</h6>

        </div>
        <div className="" style={{height:"110px",width:"130px",position:"relative",left:"500px",top:"-190px"}}>
        <div className="" style={{height:"110px",width:"130px",position:"relative",left:"-480px",top:"110px"}}>
          <img src={Idcard1} alt="" height={"100px"} width={"120px"} />
        </div>
          <img src={IDcard2} alt="" height={"100px"} width={"120px"} />
        </div>

        </div>
    </div>
  );
};



export default ProfilePage;
