import axios from "axios";
import { resetRequestUrlForJsonServer } from "./json_server_api_urls";

export default function (options = {}) {
  const axiosInstance = axios.create();

  // request interceptor
  axiosInstance.interceptors.request.use((config) => {
    config.baseURL = "https://pixelbox.work.gd"; // PYTHON API URL
    // resetRequestUrlForJsonServer(config); // JSON SERVER API URL
    const headers = {
      "Content-Type": "application/json",
      ...options?.headers,
    };

    // https://pixelbox.work.gd
    // http://localhost:8000
    // Production Backend
    // https://imodabackend.pixelboho.com

    config.headers = { ...headers };

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    // console.log(config);

    return config;
  });

  // response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async(error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        return axiosInstance
          .post("/api/refresh_token", { refreshToken })
          .then((response) => {
            const newAccessToken = response.data.accessToken;
            localStorage.setItem("accessToken", newAccessToken);
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          })
          .catch((error) => {
            console.error("Failed to refresh access token:", error);
            throw error;
          });
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
