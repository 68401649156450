import React, { useState } from "react";
import { H4, H5 } from "../../../../AbstractElements";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import ReactQuill from "react-quill";
import { post } from "../config";
import { API_URL } from "../config/api_urls";
import { toast } from "react-toastify";

function CreateMessage() {
  const [text, setText] = useState("");
   
  const [number,setNumber] = useState("+")
  const [link,setLink] = useState("")
  const [file,setFile] = useState("")

  const handleTextChange = (content, delta, source, editor) => {
    // Get plain text without HTML tags
    const plainText = editor.getText();
    setText(plainText);
  };

  const handleNumber = (e) => {
    const inputNumber = e.target.value;
    // Ensure that the value always starts with '+'
    if (inputNumber === '' || inputNumber === '+') {
      setNumber('+');
    } else if (inputNumber.startsWith('+')) {
      setNumber(inputNumber);  // Save only if it starts with '+'
    }
  };

  const handleLink = (e)=>{
    setLink(e.target.value)
  }
  
  console.log(text);

  const handleFile = (e)=>{
    setFile(e.target.value)
  }

  const bulkMessage = () => {
      const phoneNumber = number;

      const formData = new FormData();
        formData.append('recipient_number',phoneNumber);
        formData.append('message_body',text)
        formData.append('media_url',file)
        formData.append('link',link)

        // console.log(galleryImg);
        
        console.log(formData);
        

        post(API_URL.WHATSAPP_MESSAGE.POST_MESSAGE,formData)
        .then((response)=>{
          console.log(response);
          setText('')
          // handleClose()
          toast.success('send',{autoClose:1000})
        }).catch((error)=>{
          console.error(error);
        })
};
  

  const handleSms = async () => {

    console.log(number,text,link);
  
    try {
        const smsData = new FormData();
        smsData.append('recipient', number);
        smsData.append('body', text);
        smsData.append('link',link)

        console.log(smsData);
        

        const response = await post(API_URL.SMS_MESSAGE.POST_SMS, smsData);
        console.log(response);
        toast.success("send", { autoClose: 1000 });
    } catch (error) {
        console.error('Error sending SMS:', error);
    }
};


  return (
    <div>
      {/* <div className="d-flex align-items-center justify-content-between  p-4">
        <H4 attrH4={{ className: "text-muted m-0" }}>CREATE MESSAGE</H4>
      </div> */}
      <div>
        <Row className="p-2 d-flex justify-content-center align-items-center">
          <Col className="shadow rounded p-3" lg={5}>
            <H5 attrH5={{ className: "text-muted my-3" }}>
              CREATE WHATSAPP MESSAGE
            </H5>
            <Form>
              <FormGroup>
                <Label for="number">To Number</Label>
                <Input
                  id="number"
                  name="number"
                  value={number}
                  onChange={handleNumber}
                  placeholder="Enter phone numebr"
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Text Area</Label>
                <ReactQuill
                  theme="snow"
                  placeholder="Enter the message..."
                  style={{ height: "auto" }}
                  //   value={text}
                  onChange={handleTextChange}
                />
                <style jsx>{`
                  .ql-container {
                    min-height: 200px; /* Set a minimum height */
                  }
                  .ql-editor {
                    min-height: 150px; /* Adjust the height of the text area */
                  }
                `}</style>
              </FormGroup>
              <FormGroup>
                <Label for="exampleFile">File</Label>
                <Input onChange={handleFile} id="exampleFile" placeholder="Enter image url"  name="file" type="text" />
              </FormGroup>
              <FormGroup>
                <Label for="link">Links</Label>
                <Input
                  id="link"
                  name="link"
                  placeholder="Enter link"
                  type="text"
                  value={link}
                  onChange={handleLink}
                />
              </FormGroup>
              <Button onClick={bulkMessage}>Save</Button>
            </Form>
          </Col>
          <Col lg={1}></Col>
          <Col className="shadow rounded p-3" lg={5}>
            <H5 attrH5={{ className: "text-muted my-3" }}>
              CREATE SMS MESSAGE
            </H5>
            <Form>
            <FormGroup>
                <Label for="number">To Number</Label>
                <Input
                  id="number"
                  name="number"
                  value={number}
                  onChange={handleNumber}
                  placeholder="Enter phone numebr"
                  type="text"
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="exampleSelect">Select</Label>
                <Input id="exampleSelect" name="select" type="select">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </FormGroup> */}
              {/* <FormGroup>
                <Label for="exampleSelectMulti">Select Multiple</Label>
                <Input
                  id="exampleSelectMulti"
                  multiple
                  name="selectMulti"
                  type="select"
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Input>
              </FormGroup> */}
              <FormGroup>
                <Label for="exampleText">Text Area</Label>
                <ReactQuill
                  theme="snow"
                  placeholder="Enter the message..."
                  style={{ height: "auto" }}
                  //   value={text}
                  onChange={handleTextChange}
                />
                <style jsx>{`
                  .ql-container {
                    min-height: 200px; /* Set a minimum height */
                  }
                  .ql-editor {
                    min-height: 150px; /* Adjust the height of the text area */
                  }
                `}</style>
              </FormGroup>
              <FormGroup>
                <Label for="link">Links</Label>
                <Input
                  id="link"
                  name="link"
                  placeholder="Enter link"
                  type="text"
                  value={link}
                  onChange={handleLink}
                />
              </FormGroup>
              <Button onClick={handleSms}>Save</Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateMessage;
