import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import FormCommon from '../common/FormCommon';
import { get, patch, post } from '../config';
import { API_URL } from '../config/api_urls';
import { toast, ToastContainer } from 'react-toastify';
import { constructFormData } from '../config/imageHandle';

const EditIdcard = () => {
    const location = useLocation();
    const editIdCardData = location.state?.idCardData;
 const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [users,setUsers] = useState([]);
    const [userRoles,setUserRoles] = useState([]);
    const [idcards,setIdcards] = useState([]);
    const [regionals,setRegionals] = useState([]);
    const [formDataConfig, setFormDataConfig] = useState({
        initialState: {
          id: null,
          name:"",
          image:"",
          user_role_name:"",
          regional_partner_name:"",
          membership_id:"",
          whatsapp_number:"",
          phone_number: "",
          adhar_front_image:"",
          adhar_back_image:"",
          validity_date:"",
          status:"",
          payment_status :"",
          total :""
        },
        fields: [
          {
            name: "name",
            label: "FullName",
            type: "text",
            placeholder: "Enter FullName",
            required: true,
          },
            {
                name: "image",
                label: "Image",
                type: "file",
                required: true,
            },
          
          {
            name: "user_role_name",
            label: "user Role",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "regional_partner_name",
            label: "Regional Partner",
            type: "select",
            options:[],
            required: true,
          },
          {
            name: "membership_id",
            label: "membership id",
            type: "text",
            required: true,
          },
          {
            name: "whatsapp_number",
            label: "Whatsapp Number",
            type: "tel",
            placeholder:"Enter Whatsapp Number",
            required: true,
          },
          {
            name: "phone_number",
            label: "Phone Number",
            type: "tel",
            placeholder:"Enter Phone Number",
            required: true,
          },
          {
            name: "adhar_front_image",
            label: "Adhar Front Image",
            type: "file",
            required: true,
        },
        {
          name: "adhar_back_image",
          label: "Adhar Back Image",
          type: "file",
          required: true,
      },
      {
        name: "validity_date",
        label: "Validity Date",
        type: "date",
        required: true,
      },
      {
        name: "status",
        label: "Status",
        type: "select",
        options:[
          {label:"select Status", disabled:true},
          {value:"Applied",label:"Applied"},
          {value:"Approved",label:"Approved"},
          {value:"Rejected",label:"Rejected"}
        ],
        required: true,
      },
      // {
      //   name: "payment_status",
      //   label: "Payment Status",
      //   type: "select",
      //   options:[
      //     {label:"select Payment Status", disabled:true},
      //     {value:"paid",label:"paid"},
      //     {value:"unpaid",label:"unpaid"}
      //   ],
      //   required: true,
      // },
      // {
      //   name: "total",
      //   label: "Total",
      //   type: "text",
      //   placeholder:"Enter Total Amount Paid",
      //   required: true,
      // },
        ],
      });
    

      useEffect(() => {
        if (editIdCardData) {
          setIsEditing(true);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
              ...editIdCardData,
              user_role_name :userRoles.find(i=>i.label === editIdCardData?.user_role_name)?.value,
              regional_partner_name: regionals.find(i=>i.label === editIdCardData?.regional_partner_name)?.value
            }
          }));
        } else {
          setIsEditing(false);
          setFormDataConfig(value => ({
            ...value,
            initialState: {
                id: null,
                name:"",
                image:"",
                user_role_name:"",
                regional_partner_name:"",
                membership_id:"",
                whatsapp_number:"",
                phone_number: "",
                adhar_front_image:"",
                adhar_back_image:"",
                validity_date:"",
                status:"",
                payment_status :"",
                total :""
            },
          }));
        }
      }, [editIdCardData]);

      useEffect(()=>{
        fetchUserRoles();
        fetchUsers();
        fetchRegionalPartners();
        fetchIdCardList();
      },[])


      //users dropdown
      const fetchUsers = useCallback(async () => {
        try {
          const response = await get(API_URL.USERS.GET_USERS);
          const users_ids = response?.data?.map((i) => ({
            label: i.name,
            value: i.uuid
          }));
          users_ids.unshift({ label: "Select User", value: "" });
          if (users_ids.length > 0) {
            setUsers(users_ids)
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "user_uuid" ? { ...field, options: users_ids } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);



      //user role dropdown
      const fetchUserRoles = useCallback(async () => {
        try {
          const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
          const userRole_ids = response?.data?.map((i) => ({
            label: i.role,
            value: i.id
          }));
          userRole_ids.unshift({ label: "Select User Role", value: "" });
          if (userRole_ids.length > 0) {
            setUserRoles(userRole_ids)
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "user_role_name" ? { ...field, options: userRole_ids } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

console.log('edited full dta',editIdCardData);


      //regional partners dropdown
      const fetchRegionalPartners = useCallback(async () => {
        try {
          const response = await get(API_URL.REGIONAL_PARTNERS.GET_REGIONALS);
          const regionals_ids = response?.data?.map((i) => ({
            label: i.name,
            value: i.id
          }));
          regionals_ids.unshift({ label: "Select Regional", value: "" });
          if (regionals_ids.length > 0) {
            setRegionals(regionals_ids)
            setFormDataConfig((prevConfig) => ({
              ...prevConfig,
              fields: prevConfig.fields.map((field) =>
                field.name === "regional_partner_name" ? { ...field, options: regionals_ids } : field
              ),
            }));
          }
    
        } catch (error) {
          console.error("Error:", error);
        }
      }, []);

     

      const fetchIdCardList = useCallback(() => { 
        get(API_URL.IDCARDS.GET_IDCARDS)
          .then((response) => {
            if (response?.data.length > 0) {
              setIdcards(response.data);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, []);


console.log('update',editIdCardData);

      const handleNavigateToIdCards = () => {
        navigate(`${process.env.PUBLIC_URL}/id-card/:layout`)
      }
    


      const onSave = (formData) => {
        const values = {
          id : formData?.id || '',
          status : formData?.status
        }
        
        if (formData.id) {
          patch(API_URL.IDCARDS.PATCH_IDCARD(formData.id), values)
            .then((response) => {
              toast.success("Updated successfully",{autoClose:1000});
              handleNavigateToIdCards();
            })
            .catch((error) => {
              console.error("Error updating data:", error);
              toast.error("Oops! something went wrong, try again",{autoClose:1000});
            });
        } else {
          toast("Oops!something went wrong!",{autoClose:1000})
        }
      };

    return (
        <div>
        <FormCommon
        title={`${editIdCardData ? 'Edit' : 'Add'} Id Card`}
        formDataConfig={formDataConfig}
        onSave={onSave}
        // editMode={!!formDataConfig?.initialState?.id}
      />
    </div>
       
    );
};

export default EditIdcard;