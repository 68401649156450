import React, { useCallback, useEffect, useState } from 'react'
import FormCommon from '../common/FormCommon';
import EditCommonModal from '../common/EditCommonModal';
import { get, patch } from '../config';
import { API_URL } from '../config/api_urls';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';

function AddApplication({applicationData,isOpen,toggle,title,onSave,onCancel}) { 
 
 
   const [users,setUsers] = useState([]);
   const [userRoles,setUserRoles] = useState([]);
   const [events,setEvents] = useState([]);
   const[isEditing,setIsEditing] = useState(false);
    const [formDataConfig, setFormDataConfig] = useState({
 
            initialState: {
              id: null,
              user_name: "",
              event: "",
              user_role_name: "",
              status:"",
              note:""
            },
            fields: [
              {
                name: "user_name",
                label: "select user",
                type: "select",
                required: true,
                options: []
              },
              {
                name: "event",
                label: "Select Event",
                type: "select",
                required: true,
                options: []
              },
              {
                name: "user_role_name",
                label: "Select Role",
                type: "select",
                required: true,
                options: []
              },
              {
                name: "status",
                label: "status",
                type: "select",
                required: true,
                options: [
                  { value: "pending", label: "Pending" },
                  { value: "paid", label: "Paid" },
                  { value: "rejected", label: "Rejected" },
                  { value: "approved", label: "Approved" }
                ]
              },
              {
                name: "note",
                label: "Note",
                type: "text",
                placeholder:"Enter a note about event",
                required: true,
              },
              
            ],
            imageField: "image",
          });

          console.log(applicationData);
          
          
          useEffect(() => {
            if (applicationData) {
              setIsEditing(true);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  ...applicationData,
                  user_name: users.find(i=>i.label === applicationData?.user_name) ,
                  user_role_name: userRoles.find(i=>i.label === applicationData?.user_role_name),
                  event: events.find(i=>i.value === applicationData?.event?.uuid),
                  note: applicationData?.notes                

              }
              }));
            } else {
              setIsEditing(false);
              setFormDataConfig(value => ({
                ...value,
                initialState: {
                  id: null,
                  user_name: '',
                  user_role_name: '',
                  event:'',
                  status:''
                },
              }));
            }
          }, [applicationData]);

          // useEffect(() => {
          //   if (applicationData) {
          //     setIsEditing(true);
          //     setFormDataConfig((value) => ({
          //       ...value,
          //       initialState: {
          //         ...applicationData,
          //         user_name: applicationData.user_name, // Directly set the user UUID/ID
          //         user_role_name: applicationData.user_role_name, // Same for user role
          //         event: applicationData.event_details?.uuid // Set event UUID
          //       }
          //     }));
          //   } else {
          //     setIsEditing(false);
          //     setFormDataConfig((value) => ({
          //       ...value,
          //       initialState: {
          //         id: null,
          //         user_name: '',
          //         user_role_name: '',
          //         event: ''
          //       }
          //     }));
          //   }
          // }, [applicationData, users, events, userRoles]);
          
          console.log(isEditing);
          
          console.log(formDataConfig);
          

          useEffect(() => {
            fetchUsers();
            fetchEvents();
            fetchUserRoles();
          }, [])
        
          //users dropdown
          const fetchUsers = useCallback(async () => {
            try {
              const response = await get(API_URL.USERS.GET_USERS);
              const user_uuids = response?.data?.map((i) => ({
                label: i.name,
                value: i.uuid,
              }));
              user_uuids.unshift({ label: "Select User", value: "" });
              if (user_uuids.length > 0) {
                setUsers(user_uuids);
                setFormDataConfig((prevConfig) => ({
                  ...prevConfig,
                  fields: prevConfig.fields.map((field) =>
                    field.name === "user_name" ? { ...field, options: user_uuids } : field
                  ),
                }));
              }
        
            } catch (error) {
              console.error("Error:", error);
            }
          }, []);


           //event dropdown
           const fetchEvents = useCallback(async () => {
            try {
              const response = await get(API_URL.EVENTS.GET_EVENTS);
              console.log(response.data);

              const event_uuids = response?.data?.map((i) => ({
                label: i.title,
                value: i.uuid
              }));
              event_uuids.unshift({ label: "Select Event", value: "" });
              if (event_uuids.length > 0) {
                setEvents(event_uuids)
                setFormDataConfig((prevConfig) => ({  
                  ...prevConfig,
                  fields: prevConfig.fields.map((field) =>
                    field.name === "event" ? { ...field, options: event_uuids } : field
                  ),
                }));
              }
        
            } catch (error) {
              console.error("Error:", error);
            }
          }, []);

          console.log(events);
          


          //user role dropdown
          const fetchUserRoles = useCallback(async () => {
            try {
              const response = await get(API_URL.USER_ROLE.USER_ROLE_GET);
              const userRole_ids = response?.data?.map((i) => ({
                label: i.role,
                value: i.id
              }));
              userRole_ids.unshift({ label: "Select User Role", value: "" });
              if (userRole_ids.length > 0) {
                setUserRoles(userRole_ids)
                setFormDataConfig((prevConfig) => ({
                  ...prevConfig,
                  fields: prevConfig.fields.map((field) =>
                    field.name === "user_role_name" ? { ...field, options: userRole_ids } : field
                  ),
                }));
              }
        
            } catch (error) {
              console.error("Error:", error);
            }
          }, []);

          

        console.log(formDataConfig.initialState);
          
        console.log(formDataConfig.fields);
        
            const handleSave = () => {
              onSave(formDataConfig.initialState);
            };
                  
            const handleCancel = () => {
              onCancel(); 
              // setIsEditing(false) 
            };      

            const setUser = (e) => {
              setFormDataConfig({
                ...formDataConfig,
                initialState: {
                  ...formDataConfig.initialState,
                  user_name: e
                }
              })
            }

            const setEvent =(e)=>{
              console.log(e);
              
              setFormDataConfig({
                ...formDataConfig,
                initialState: {
                  ...formDataConfig.initialState,
                  event: e
                }
              })
            }

            const setUserRole =(e)=>{
              setFormDataConfig({
                ...formDataConfig,
                initialState: {
                  ...formDataConfig.initialState,
                  user_role_name: e
                }
              })
            }

            const setNote =(e)=>{
                setFormDataConfig((prevState) => ({
                  ...prevState,
                  initialState: {
                    ...prevState.initialState,
                    note: e.target.value,
                  },
                })); 
            }

            

            // const setEvent = (e) => {
            //   setFormDataConfig({
            //     ...formDataConfig,
            //     initialState: {
            //       ...formDataConfig.initialState,
            //       event: e.value // Only store the event UUID
            //     }
            //   });
            // };
            
            // const setUser = (e) => {
            //   setFormDataConfig({
            //     ...formDataConfig,
            //     initialState: {
            //       ...formDataConfig.initialState,
            //       user_name: e.value // Store user UUID
            //     }
            //   });
            // };
            
            // const setUserRole = (e) => {
            //   setFormDataConfig({
            //     ...formDataConfig,
            //     initialState: {
            //       ...formDataConfig.initialState,
            //       user_role_name: e.value // Store user role ID
            //     }
            //   });
            // };
            


  return (
    <EditCommonModal  
    isOpen={isOpen}
    toggle={toggle}
    title={`${isEditing ? 'Edit' : 'Add'} ${title}`}
    onSave={handleSave}
    onCancel={handleCancel}
    > 
       <div className='mb-4'>
				<FormGroup >
          <Label className="col-form-label">Select User</Label>
				  <Select
					value={formDataConfig?.initialState?.user_name}
					onChange={setUser}
					options={formDataConfig?.fields?.find(i => i.name === "user_name")?.options}
					className="js-example-basic-single col-sm-12"
					/>

          {/* <Select
            value={formDataConfig?.fields?.find(i => i.name === "user_name")?.options?.find(opt => opt.value === formDataConfig?.initialState?.user_name)}
            onChange={setUser}
            options={formDataConfig?.fields?.find(i => i.name === "user_name")?.options}
            className="js-example-basic-single col-sm-12"
          /> */}

          <Label className="col-form-label mt-3">Select Event</Label>
				  <Select
					value={formDataConfig?.initialState?.event}
					onChange={setEvent}
					options={formDataConfig?.fields?.find(i => i.name === "event")?.options}
					className="js-example-basic-single col-sm-12"
					/>

          {/* <Select
            value={formDataConfig?.fields?.find(i => i.name === "event")?.options?.find(opt => opt.value === formDataConfig?.initialState?.event)}
            onChange={setEvent}
            options={formDataConfig?.fields?.find(i => i.name === "event")?.options}
            className="js-example-basic-single col-sm-12"
          /> */}

          <Label className="col-form-label mt-3">Select User Role</Label>
				  <Select
					value={formDataConfig?.initialState?.user_role_name}
					onChange={setUserRole}
					options={formDataConfig?.fields?.find(i => i.name === "user_role_name")?.options}
					className="js-example-basic-single col-sm-12"
					/>
          
          {/* <Select
          value={formDataConfig?.fields?.find(i => i.name === "user_role_name")?.options?.find(opt => opt.value === formDataConfig?.initialState?.user_role_name)}
          onChange={setUserRole}
          options={formDataConfig?.fields?.find(i => i.name === "user_role_name")?.options}
          className="js-example-basic-single col-sm-12"
        /> */}

          <Label className="col-form-label mt-3">Status</Label>
          {/* <Select
					value={formDataConfig?.initialState?.status}
					onChange={setStatus}
					options={formDataConfig?.fields?.find(i => i.name === "status")?.options}
					className="js-example-basic-single col-sm-12"
					/> */}

          <Select
            value={formDataConfig.fields.find(i => i.name === "status")?.options.find(option => option.value === formDataConfig.initialState.status)}
            onChange={(selectedOption) => {
              // Update the status in the initialState when an option is selected
              setFormDataConfig((prevState) => ({
                ...prevState,
                initialState: {
                  ...prevState.initialState,
                  status: selectedOption.value, // Set the value from the selected option
                },
              }));
            }}
            options={formDataConfig.fields.find(i => i.name === "status")?.options}
            className="js-example-basic-single col-sm-12"
          />

          {formDataConfig.fields.find(i => i.name === "note") && (
              <>
                <Label className="mt-3">
                  {formDataConfig.fields.find(i => i.name === "note")?.label}
                </Label>
                <Input
                  type="textarea"
                  placeholder={formDataConfig.fields.find(i => i.name === "note")?.placeholder}
                  value={formDataConfig.initialState.note || ""}
                  onChange={setNote}
                  className="form-control col-sm-12"
                />
              </>
            )}

          
          
          

				</FormGroup>
			</div>

    </EditCommonModal>
  )
}

export default AddApplication