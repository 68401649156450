import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import "./FormCommon.css";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactQuill from "react-quill";
import UserDetailsModal from "../users/UserDetailsModal";
import { API_URL } from "../config/api_urls";
import { get } from "react-hook-form";

function FormCommon({
  isGallery,
  isEvenVideo,
  isModal,
  isaboutme,
  isaddresline1,
  isaddresline2,
  ispincode,
  isEditing,
  userRoles,
  formDataConfig,
  setFormDataConfig,
  onSave,
  title,
  isModel,
  addressConfig,
  AboutConfig,
  attribute,
  setAttributeConfig,
  attributeConfig,
  preference,
  preferenceConfigue,
  setPreferenceConfigue,
  sociaMedia,
  socialConfigue,
  setSocialConfigue
}) {
  const [formData, setFormData] = useState(formDataConfig.initialState || {});
  // const [addressData, setaddressData] = useState(addressConfig.initialStates || {});

  const [addressFormData, setAddressFormData] = useState({});
  const [aboutFormData, setaboutFormData] = useState({});

  const [inputsEntered, setInputsEntered] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [videoFile, setVideoFile] = useState([]);
  const [aboutMe, setAboutMe] = useState("");

  const [videoPreview, setVideoPriview] = useState([]);

  const [addressline1, setaddressline1] = useState("");
  const [addressline2, setaddressline2] = useState("");
  const [pincode, setpincode] = useState("");

  const [modal, setModal] = useState(false);
  const [profilePreview, setProfilePreview] = useState();
  const [coverPreview, setCoverPreview] = useState();

  // const [socialFormData,setSocialFormData] = useState

  console.log(preference);
  

  useEffect(() => {
    if (formDataConfig) {
      setFormData(formDataConfig.initialState || {});
    }
  }, [formDataConfig]);

  console.log(formData);

  useEffect(() => {
    if (addressConfig) {
      setAddressFormData(addressConfig.initialState || {});
    }
  }, [addressConfig]);

  console.log("addressformdata in formcommon", addressFormData);

  useEffect(() => {
    if (AboutConfig) {
      setaboutFormData(AboutConfig.initialState || {});
    }
  }, [AboutConfig]);

  console.log("aboutformdata in formcommon", aboutFormData);

  // console.log('formcommon',formData);
  // useEffect(() => {
  //   setaddressData(addressConfig.initialStates || {});
  // }, [addressConfig]);
  // console.log('formaddres',addressData);

  // Handle change for the select input and text input
  //  const handleAttribute = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedFields = [...fields];
  //   updatedFields[index][name] = value;
  //   setFields(updatedFields);
  // };

  // Handle adding a new field pair
  // const handleAddAttribute = () => {
  //   setAttributeConfig([...attributeConfig, { id: "", user_uuid: "", user_name: "", attribute_id: "", attribute_name: "", value: "" }]);
  // };

  const handleAddAttribute = () => {
  setAttributeConfig((prevConfigs) => [
    ...prevConfigs,
    {
      id: prevConfigs[0]?.id || "",
      user_name: prevConfigs[0]?.user_name || "",
      user_uuid: prevConfigs[0]?.user_uuid || "", 
      attribute_id: "",
      attribute_name: "",
      value: "",
    },
  ]);
};

const handleAddSocial = () => {
  setSocialConfigue((prevConfigs) => [
    ...prevConfigs,
    {
      id: prevConfigs[0]?.id || "",
      user_name: prevConfigs[0]?.user_name || "",
      user_uuid: prevConfigs[0]?.user_uuid || "", 
      social_id: "",
      socialmedia_name: "",
      link: "",
    },
  ]);
};

  const handleAddPreference = () => {
    setPreferenceConfigue((prevConfigs) => [
      ...prevConfigs,
      {
        id: prevConfigs[0]?.id || "",
        user_name: prevConfigs[0]?.user_name || "",
        user_uuid: prevConfigs[0]?.user_uuid || "", 
        preference_ids: "",
        preference_name: "",
        value: "",
      },
    ]);
  };

  const handleInputChange = (e) => {
    const { name, value, files, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setInputsEntered(true);
  };

  const handleAddress = (e) => {
    const { name, value, files, type, checked } = e.target;
    setAddressFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };
  console.log("addressfordata", addressFormData);

  const handleAttribute = (event,index) => {

    const updatedConfigs = [...attributeConfig];
    updatedConfigs[index].value = event.target.value;
    console.log(updatedConfigs);
    
    setAttributeConfig(updatedConfigs);
    setInputsEntered(true);
  };

  const handleSocialMedia = (event,index) => {

    const updatedConfigs = [...socialConfigue];
    updatedConfigs[index].link = event.target.value;
    console.log(updatedConfigs);
    
    setSocialConfigue(updatedConfigs);
    setInputsEntered(true);
  };

  // console.log(socialConfigue);
  

  const handleAttributeSelect = (event,index) => {

    const {value} = event.target

    const updatedConfigs = [...attributeConfig];
    updatedConfigs[index].attribute_id = Number(value);
    console.log(updatedConfigs);
    
    setAttributeConfig(updatedConfigs);
    setInputsEntered(true);
  };

  const handlePreferenceSelect = (event,index) => {

    console.log(preferenceConfigue);
    const {value} = event.target
    
    const updatedConfigs = [...preferenceConfigue]
    updatedConfigs[index].preference_ids = Number(value)
    console.log(updatedConfigs);
    
    setPreferenceConfigue(updatedConfigs)
    setInputsEntered(true);
  };

  const handleSocialSelect = (event,index) => {
    const {value} = event.target
    const updatedConfigs = [...socialConfigue];
    updatedConfigs[index].social_id = Number(value);
    setSocialConfigue(updatedConfigs);
    setInputsEntered(true);
  };

  const handleAbout = (e) => {
    const { name, value, files, type, checked } = e.target;
    setaboutFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "file" ? files[0] : type === "checkbox" ? checked : value,
    }));
    setInputsEntered(true);
  };

  console.log("abutformdata", aboutFormData);

  const handleMultiSelectChange = (selectedOptions, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
    setInputsEntered(true);
  };

  //preview
  const handlePrintPreview = (e) => {
    e.preventDefault();

    // Check if all fields are filled before showing the preview
    if (formData) {
      // Show print preview by opening the print dialog
      window.print();
    } else {
      alert("Please fill out all fields before previewing.");
    }
  };

  //gallery
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // convert FileList to an array
    const names = files.map((file) => file.name); // get the file names

    const imageUrl = files.map((file) => URL.createObjectURL(file));
    setImagePreview(imageUrl);

    setFileNames(files);
    setInputsEntered(true);
  };

  const handleAboutMe = (e) => {
    setAboutMe(e.target.value);
  };

  //button
  const handleButtonClick = () => {
    const newSocialMediaField = {
      name: "social_media_id",
      label: "Social media",
      type: "select",
      options: [],
      required: false,
    };

    const newSocialMediaLinkField = {
      name: `social_media_link_${formDataConfig.fields.length}`,
      label: "Social Media Link",
      type: "text",
      placeholder: "Enter the social media link",
      required: false,
    };

    // Get the index of the "social_media_id" field
    setFormDataConfig((prevConfig) => ({
      ...prevConfig,
      fields: [
        ...prevConfig.fields,
        newSocialMediaField,
        newSocialMediaLinkField,
      ],
    }));
  };

  //event video
  const handleEventVideo = (e) => {
    const files = Array.from(e.target.files);

    const imageUrl = files.map((file) => URL.createObjectURL(file));
    setVideoPriview(imageUrl);

    setVideoFile(files);
    setInputsEntered(true);
  };
  // console.log(videoFile);

  // console.log(fileNames);

  const removeImage = (indexToRemove) => {
    setImagePreview((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeVideo = (indexToRemove) => {
    setVideoPriview((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  //modal
  const toggle = () => setModal(!modal);

  const handleSave = () => {
    if (!inputsEntered) {
      toast.warning("Please enter the inputs!");
      return;
    }

    if (onSave)
      onSave(
        formData,
        fileNames,
        videoFile,
        aboutMe,
        addressline1,
        addressline2,
        pincode,
        addressFormData,
        aboutFormData
      );
  };

  // const renderField = (field) => {
  //   if (!field) return null;
  //   const fieldValue = formData[field.name] || [];
  //   switch (field.type) {
  //     case "file":
  //       const renderImage =
  //         typeof fieldValue === "string" ? (
  //           <img src={`${fieldValue}`} className="dummy-image" alt="IMAGE" />
  //         ) : null;
  //       return (
  //         <div className="file-field-container input-field d-flex">
  //           <input
  //             type="file"
  //             id={field.name}
  //             className="styled-input"
  //             name={field.name}
  //             onChange={handleInputChange}
  //             required={field.required}
  //           />
  //           <div className="p-3">{renderImage}</div>
  //         </div>
  //       );

  //     case "checkbox":
  //       return (
  //         <label className=" d-flex ">
  //           <input
  //             type="checkbox"
  //             value={field.name}
  //             id={field.name}
  //             name={field.name}
  //             checked={!!formData[field.name]}
  //             onChange={handleInputChange}
  //             required={field.required}
  //           />
  //         </label>
  //       );
  //     case "select":
  //       return (
  //         <select
  //           name={field.name}
  //           value={formData[field.name] || ""}
  //           onChange={handleInputChange}
  //           className="styled-input"
  //           required={field.required}
  //         >
  //           {Array.isArray(field.options) ? (
  //             field.options.map((option) => (
  //               <option
  //                 key={option.value}
  //                 value={option.value}
  //                 disabled={option.disabled}
  //               >
  //                 {option.label}
  //               </option>
  //             ))
  //           ) : (
  //             <option disabled>No options available</option>
  //           )}
  //         </select>
  //       );
  //     case "multiselect":
  //       return (
  //         <Select
  //           isMulti
  //           name={field.name}
  //           options={field.options}
  //           value={field.options.filter((option) =>
  //             fieldValue.includes(option.value)
  //           )}
  //           onChange={(selectedOptions) =>
  //             handleMultiSelectChange(selectedOptions, field.name)
  //           }
  //           className="basic-multi-select"
  //           classNamePrefix="select"
  //         />
  //       );

  //     case "group":
  //       return (
  //         <div className="range-group">
  //           <Row>
  //             {field.fields.map((subField) => (
  //               <Col key={subField.name}>
  //                 <input
  //                   type={subField.type}
  //                   id={subField.name}
  //                   className="styled-input"
  //                   name={subField.name}
  //                   placeholder={subField.placeholder}
  //                   onChange={handleInputChange}
  //                   value={formData[subField.name] || ""}
  //                   required={field.required}
  //                 />
  //               </Col>
  //             ))}
  //           </Row>
  //         </div>
  //       );
  //     default:
  //       return (
  //         <input
  //           type={field.type}
  //           id={field.name}
  //           className="styled-input"
  //           name={field.name}
  //           placeholder={field.placeholder}
  //           onChange={handleInputChange}
  //           value={formData[field.name] || ""}
  //           required={field.required}
  //         />
  //       );
  //   }
  // };

  const renderField = (field) => {
    if (!field) return null;
    const fieldValue = formData[field.name] || [];

    switch (field.type) {
      case "file":
        const renderImage =
          typeof fieldValue === "string" ? (
            <img src={fieldValue} className="dummy-image" alt="IMAGE" />
          ) : null;
        return (
          <div className="file-field-container input-field d-flex">
            <input
              type="file"
              id={field.name}
              className="styled-input"
              name={field.name}
              onChange={handleInputChange}
              required={field.required}
            />
            <div className="p-3">{renderImage}</div>
          </div>
        );

      case "group":
        return (
          <div className="range-group">
            <Row>
              {field.fields.map((subField) => (
                <Col key={subField.name}>
                  <input
                    type={subField.type}
                    id={subField.name}
                    className="styled-input"
                    name={subField.name}
                    placeholder={subField.placeholder}
                    onChange={handleInputChange}
                    value={formData[subField.name] || ""}
                    required={field.required}
                  />
                </Col>
              ))}
            </Row>
          </div>
        );

      case "checkbox":
        return (
          <label className="d-flex">
            <input
              type="checkbox"
              value={field.name}
              id={field.name}
              name={field.name}
              checked={!!formData[field.name]}
              onChange={handleInputChange}
              required={field.required}
            />
          </label>
        );

      case "select":
        return (
          <select
            name={field.name}
            value={formData[field.name] || ""}
            onChange={(e) => {
              handleInputChange(e);
              if (field.onChange) field.onChange(e.target.value); // Triggering field's onChange
            }}
            className="styled-input"
            required={field.required}
          >
            {isModel ? (
              Array.isArray(field.options) ? (
                field.options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </option>
                ))
              ) : (
                <option disabled>No options available</option>
              )
            ) : Array.isArray(field.options) ? (
              field.options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))
            ) : (
              <option disabled>No options available</option>
            )}
          </select>
        );

      case "multiselect":
        return (
          <Select
            isMulti
            name={field.name}
            options={field.options}
            value={field.options.filter((option) =>
              fieldValue.includes(option.value)
            )}
            onChange={(selectedOptions) =>
              handleMultiSelectChange(selectedOptions, field.name)
            }
            className="basic-multi-select"
            classNamePrefix="select"
          />
        );

      case "quill-editor":
        return (
          <div key={field.name}>
            {/* <label>{field.label}</label> */}
            <ReactQuill
              value={formData[field.name]}
              onChange={(value) => handleFieldChange(field.name, value)}
              placeholder={field.placeholder}
            />
          </div>
        );

      case "button":
        return (
          <button
            key={field.index}
            type="button"
            onClick={() => handleButtonClick(field.action)} // Handle button click based on action
            // style={styles.addButton}
            className="btn border"
          >
            {field.placeholder}
          </button>
        );

      case "group":
        return (
          <div className="range-group">
            <Row>
              {field.fields.map((subField) => (
                <Col key={subField.name}>
                  <input
                    type={subField.type}
                    id={subField.name}
                    className="styled-input"
                    name={subField.name}
                    placeholder={subField.placeholder}
                    onChange={handleInputChange}
                    value={formData[subField.name] || ""}
                    required={field.required}
                  />
                </Col>
              ))}
            </Row>
          </div>
        );

      default:
        return (
          <input
            type={field.type}
            id={field.name}
            className="styled-input"
            name={field.name}
            placeholder={field.placeholder}
            onChange={handleInputChange}
            value={formData[field.name] || ""}
            required={field.required}
          />
        );
    }
  };

  const style = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
  };

  // const profileUrl = URL.createObjectURL(formData.profile_image);
  //   setProfilePreview(profileUrl);

  //   const coverUrl = URL.createObjectURL(formData.cover_image);
  //   setCoverPreview(coverUrl);

  //   console.log(profilePreview,coverPreview);

  return (
    <Fragment>
      <Card>
        <Form
          className="form theme-form p-3"
          onSubmit={(e) => e.preventDefault()}
        >
          <h4 className="ms-3 mt-3">{title}</h4>

          <CardBody>
            {Array.isArray(formDataConfig.fields) &&
              formDataConfig.fields.map((field, index) =>
                index % 2 === 0 ? (
                  <Row key={field.name}>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="fw-bold" htmlFor={field.name}>
                          {field.label}
                        </Label>
                        {renderField(field)}
                      </FormGroup>
                    </Col>
                    {formDataConfig.fields[index + 1] && (
                      <Col md={6}>
                        <FormGroup>
                          <Label
                            className="fw-bold"
                            htmlFor={formDataConfig.fields[index + 1].name}
                          >
                            {formDataConfig.fields[index + 1].label}
                          </Label>
                          {renderField(formDataConfig.fields[index + 1])}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                ) : null
              )}

            {attributeConfig && (
              <>
                <Row>
                  <Col lg={6}>
                    <form style={styles.formContainer}>
                      {attributeConfig.map((field, index) => (
                        <div key={index} style={styles.fieldRow}>
                          {/* Select Input */}
                          <div style={styles.formGroup}>
                            <label
                              htmlFor={`select-${index}`}
                              style={styles.label}
                            >
                              Select Attribute:
                            </label>
                            <select
                              name="attribute_name"
                              id={`select-${index}`}
                              value={field.attribute_id}
                              onChange={(event)=>handleAttributeSelect(event,index)}
                              style={styles.selectInput}
                            >
                              {attribute.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
  
                          {/* Text Input */}
                          <div style={styles.formGroup}>
                            <label htmlFor={`text-${index}`} style={styles.label}>
                              Enter Value:
                            </label>
                            <input
                              type="text"
                              placeholder="Enter attribute value"
                              name="attribute_value"
                              id={`text-${index}`}
                              value={attributeConfig[index].value}
                              onChange={(event)=>handleAttribute(event,index)}
                              style={styles.textInput}
                            />
                          </div>
                        </div>
                      ))}
  
                      {/* Add New Field Button */}
                      <button
                        type="button"
                        onClick={handleAddAttribute}
                        style={styles.addButton}
                      >
                        + Add Attribute
                      </button>
                    </form>
                  </Col>
                  <Col lg={6}>
                    <form style={styles.formContainer}>
                      {preferenceConfigue.map((field, index) => (
                        <div key={index} style={styles.fieldRow}>
                          {/* Select Input */}
                          <div style={styles.formGroup}>
                            <label
                              htmlFor={`select-${index}`}
                              style={styles.label}
                            >
                              Select Preference:
                            </label>
                            <select
                              name="selectedOption"
                              id={`select-${index}`}
                              value={field.preference_ids}
                              onChange={(event)=>handlePreferenceSelect(event,index)}
                              style={styles.selectInput}
                            >
                              {preference.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
  
                      {/* Add New Field Button */}
                      <button
                        type="button"
                        onClick={handleAddPreference}
                        style={styles.addButton}
                      >
                        + Add Preference
                      </button>
                    </form>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <form style={styles.formContainer}>
                    {socialConfigue.map((field, index) => (
                      <div key={index} style={styles.fieldRow}>
                        {/* Select Input */}
                        <div style={styles.formGroup}>
                          <label
                            htmlFor={`select-${index}`}
                            style={styles.label}
                          >
                            Select Socialmedia:
                          </label>
                          <select
                            name="attribute_name"
                            id={`select-${index}`}
                            value={field.social_id}
                            onChange={(event)=>handleSocialSelect(event,index)}
                            style={styles.selectInput}
                          >
                            {sociaMedia.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Text Input */}
                        <div style={styles.formGroup}>
                          <label htmlFor={`text-${index}`} style={styles.label}>
                            Enter Link:
                          </label>
                          <input
                            type="text"
                            placeholder="Enter social media link"
                            name="socialmedia_value"
                            id={`text-${index}`}
                            value={socialConfigue[index].link}
                            onChange={(event)=>handleSocialMedia(event,index)}
                            style={styles.textInput}
                          />
                        </div>
                      </div>
                    ))}

                    {/* Add New Field Button */}
                    <button
                      type="button"
                      onClick={handleAddSocial}
                      style={styles.addButton}
                    >
                      + Add Socialmedia
                    </button>
                  </form>
                  </Col>
                  <Col lg={6}></Col>
                </Row>
              </>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Address line1</Label>
                <Input
                  type="text"
                  name="address_line1" // This should match the key in the state object
                  placeholder={
                    isEditing
                      ? "Edit your address line 1"
                      : "Enter your address line 1"
                  }
                  onChange={handleAddress}
                  value={addressFormData.address_line1 || ""} // Add value to ensure it's controlled
                />
              </FormGroup>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Address line2</Label>
                <Input
                  type="text"
                  name="address_line2"
                  placeholder={
                    isEditing
                      ? "Edit your address line 2"
                      : "Enter your address line 2"
                  }
                  onChange={handleAddress}
                  value={addressFormData.address_line2 || ""} // Ensure controlled input
                />
              </FormGroup>
            )}

            {addressConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Pincode</Label>
                <Input
                  type="number"
                  name="pincode"
                  placeholder={
                    isEditing ? "Edit your pincode" : "Enter your pincode"
                  }
                  onChange={handleAddress}
                  value={addressFormData.pincode || ""} // Ensure controlled input
                />
              </FormGroup>
            )}
            {AboutConfig && (
              <FormGroup className="mb-3 mt-2 d-flex flex-column">
                <Label className="fw-bold">Aboutme</Label>
                <Input
                  type="text"
                  name="about"
                  placeholder={
                    isEditing ? "Edit your about me" : "Enter your about me"
                  }
                  onChange={handleAbout}
                  value={aboutFormData.about || ""} // Ensure controlled input
                />
              </FormGroup>
            )}

            {isGallery && (
              <FormGroup
                controlId="formFileMultiple"
                className="mb-3 mt-2 d-flex flex-column"
              >
                <Label className="mt-3 fw-bold">Input Video</Label>
                <input
                  className="border rounded"
                  type="file"
                  accept="video/*"
                  multiple
                  onChange={handleEventVideo}
                />

                <div
                  className="d-flex my-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {videoPreview.map((video, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <video
                        autoPlay
                        src={video}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeVideo(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>

                <Label className="fw-bold">Input Gallery Images</Label>
                <input
                  className="border rounded"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />

                <div
                  className="d-flex mt-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {imagePreview.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={image}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeImage(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </FormGroup>
            )}

            {isEvenVideo && (
              <FormGroup
                controlId="formFileMultiple"
                className="mb-3 mt-2 d-flex flex-column"
              >
                <Label className="fw-bold">Input Video</Label>
                <input
                  className="border"
                  type="file"
                  accept="video/*"
                  multiple
                  onChange={handleEventVideo}
                />

                <div
                  className="d-flex my-2"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {videoPreview.map((video, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <video
                        autoPlay
                        src={video}
                        style={{ borderRadius: "5px" }}
                        alt={`preview ${index}`}
                        height="100"
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          background: "black",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => removeVideo(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </FormGroup>
            )}

            {/* {isEvenVideo && <FormGroup
              controlId="formFileMultiple"
              className="mb-3 mt-2 d-flex flex-column"
            >
              <Label>Input Video</Label>
              <input className="border" type="file" accept="video/*" multiple onChange={handleEventVideo} />
            </FormGroup>} */}
          </CardBody>

          {!isModal && (
            <div className="d-flex">
              <div className="w-100 d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    onClick: handleSave,
                  }}
                >
                  Save Changes
                </Btn>
              </div>
              {isGallery && <div className="w-100 d-flex justify-content-end">
                <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    onClick: toggle,
                  }}
                >
                  Preview
                </Btn>
              </div>}
            </div>
          )}
        </Form>
      </Card>
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          style={{ maxWidth: "800px", overflowX: "hidden" }}
          scrollable
        >
          <ModalHeader toggle={toggle}>User Preview</ModalHeader>
          <ModalBody
            className="container"
            style={{
              padding: "20px",
              width: "100%",
              height: "100vh",
              overflowX: "hidden",
            }}
          >
            <div>
              <div style={{ position: "relative" }}>
                <div className="row">
                  <img
                    src={formData.cover_image}
                    alt="Cover Image"
                    style={{
                      width: "100%",
                      // height: "350px",
                      objectFit: "cover",
                    }}
                  />
                </div>

                <div
                  className="row justify-content-center"
                  style={{ position: "relative", top: "-250px" }}
                >
                  <div className="col-auto">
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={formData.profile_image}
                        alt="User Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          border: "1px solid #ddd",
                          borderRadius: "50%",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          padding: "5px",
                          transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.transform = "scale(1.2)")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.transform = "scale(1.0)")
                        }
                      />
                    </div>

                    {/* <div className="col-auto">
                <h3
                  className="text-start"
                  style={{ color: "black", marginLeft: "40px" }}
                >
                  {formData.name}
                </h3>
              </div> */}
                  </div>
                </div>
              </div>
              <div style={{ position: "relative", top: "-120px" }}>
                <div className="">
                  <h5>
                    <i className="fa-solid fa-id-card m-3"></i>BASIC INFO
                  </h5>
                  <div className="" style={{ marginLeft: "15px" }}>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      {/* First Row */}
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Name :</strong> {formData.name}
                        </p>
                      </div>
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Gender :</strong> {formData.gender}
                        </p>
                      </div>

                      {/* Second Row */}
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>DOB :</strong> {formData.date_of_birth}
                        </p>
                      </div>
                      <div style={{ flex: "0 0 48%" }}>
                        <p>
                          <strong>Age :</strong> {formData.age}
                        </p>
                      </div>

                      {/* Last Row */}
                      {/* <div style={{ flex: "0 0 100%" }}>
                  <p>
                    <strong>Category :</strong> {Category}
                  </p>
                </div> */}
                    </div>
                  </div>
                </div>
                <div className="">
                  <h5>
                    <i class="fa-solid fa-square-phone m-3"></i>CONTACT ME
                  </h5>
                  <div className="d-flex flex-row m-3" style={{ gap: "20px" }}>
                    <div className="col-4 rounded " style={style}>
                      <p
                        style={{
                          marginLeft: "0px",
                          marginTop: "10px",
                          paddingLeft: "7px",
                        }}
                      >
                        <i class="fa-solid fa-phone"></i>{" "}
                        {formData.phone_number || "N/A"}
                      </p>
                    </div>
                    <div className="col-4  rounded" style={style}>
                      <p
                        style={{
                          marginLeft: "0px",
                          marginTop: "10px",
                          paddingLeft: "7px",
                        }}
                      >
                        <i class="fa-solid fa-envelope"></i>{" "}
                        {formData.email || "N/A"}
                      </p>
                    </div>
                    <div className="col-4  rounded" style={style}>
                      <p
                        style={{
                          marginLeft: "0px",
                          marginTop: "10px",
                          paddingLeft: "7px",
                        }}
                      >
                        <i class="fa-brands fa-whatsapp"></i>{" "}
                        {formData.whatsapp_number || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="m-4"
                  style={{ display: "flex", flex: "wrap", gap: "10px" }}
                >
                  <div style={{ flex: "0 0 48%" }}>
                    <p>
                      <strong>Category :</strong> {addressFormData.pincode}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 48%" }}>
                    <p>
                      <strong>User Role :</strong> {addressFormData.pincode}
                    </p>
                  </div>
                </div>
                <div
                  className="flex-column m-4"
                  style={{ display: "flex", flex: "wrap", gap: "10px" }}
                >
                  <div style={{ flex: "0 0 48%" }}>
                    <p>
                      <strong>Address Line 1 :</strong>{" "}
                      {addressFormData.address_line1}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 48%" }}>
                    <p>
                      <strong>Address Line 2 :</strong>{" "}
                      {addressFormData.address_line2}
                    </p>
                  </div>
                  <div style={{ flex: "0 0 48%" }}>
                    <p>
                      <strong>Pincode :</strong> {addressFormData.pincode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              done
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Fragment>
  );
}

// Styles
const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    // border: '1px solid #ccc',
    borderRadius: "8px",
  },
  fieldRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginRight: "10px",
  },
  label: {
    marginBottom: "8px",
    fontWeight: "bold",
  },
  selectInput: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  textInput: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  addButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
};

export default FormCommon;
